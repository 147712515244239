import {
  InputChangeEventDetail,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import React, { useContext, useEffect, useRef, useState } from "react";
import blood from "../../assets/icons/blood-sugar-4.svg";
import { UserContext } from "../../lib/context";
import { updateLogGlucoseDB } from "../../API/logupdateAPI";
import { Timestamp } from "firebase/firestore";
import { useLocation } from "react-router-dom";

function LogGlucose() {
  const { profile, user } = useContext(UserContext);
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [glucoseLevel, setGlucoseLevel] = useState<number | undefined>(
    undefined
  );

  const [when, setWhen] = useState<string>("");
  const timestamp = Timestamp.now();
  const date = timestamp.toDate().toISOString().substr(0, 10);

  const location = useLocation();
  const [pathName, setPathName] = useState("");

  useEffect(() => {
    setPathName(location.pathname);
  }, [location.pathname]);

  const handleUpdateLogGlucoseDoc = async () => {
    try {
      await updateLogGlucoseDB({
        email: user.email,
        timestamp: new Date(),
        date: date,
        glucoseLevel: glucoseLevel,
        uid: user.uid,
        when: when,
      });

      modal.current?.dismiss(input.current?.value, "confirm");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleInputChanged = (event: CustomEvent<InputChangeEventDetail>) => {
    const value = event.detail.value;
    setGlucoseLevel(value ? parseFloat(value) : undefined);
  };

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {}

  return (
    <>
      {profile.hasGlucose || profile.diabetes !== "no" ? (
        <>
          <div className="journal-square mood ion-text-center" id="open-modal-glucose">
            <IonIcon className="log-icon" icon={blood}></IonIcon>
            <br />
            <IonLabel className="jour">Log Glucose</IonLabel>
          </div>
          <IonModal
            ref={modal}
            trigger="open-modal-glucose"
            onWillDismiss={(ev) => onWillDismiss(ev)}
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onClick={() => modal.current?.dismiss()}>
                    Cancel
                  </IonButton>
                </IonButtons>
                <IonTitle>Log Glucose</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              {/* <IonItem lines="none">
                <IonLabel className="ion-padding" position="stacked">
                  Enter your Glucose level
                </IonLabel>
                  <IonInput
                    ref={input}
                    type="number"
                    placeholder="123"
                    slot="start"
                    onIonChange={handleInputChanged}
                  />
                  <IonBadge slot="end">mg/dL</IonBadge>
              </IonItem> */}
              <IonItem className="ion-margin">
                <IonLabel position="floating">Glucose Level</IonLabel>
                <IonInput
                  type="number"
                  placeholder="87"
                  inputMode="numeric"
                  onIonChange={(e) => setGlucoseLevel(+e.detail.value)}
                  // onIonBlur={validateMealCalories}
                ></IonInput>
              </IonItem>
              <IonItem className="ion-margin">
                <IonLabel>When?</IonLabel>
                <IonSelect
                  onIonChange={(e) => setWhen(e.detail.value)}
                  // onIonDismiss={validateMealType}
                >
                  <IonSelectOption value="Fasting Glucose">Fasting Glucose</IonSelectOption>
                  <IonSelectOption value="Before Bed">Before Bed</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonButton
                className="ion-padding"
                expand="block"
                onClick={() => handleUpdateLogGlucoseDoc()}
                disabled={glucoseLevel === undefined || when === ""}
              >
                Log
              </IonButton>
            </IonContent>
          </IonModal>
        </>
      ) : null}
    </>
  );
}

export default LogGlucose;
