import { chevronDown, chevronUp, refresh } from "ionicons/icons";
import "./style.css";
import { IonIcon, IonLabel } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { timeSince } from "../../../../../../../lib/Helpers/time";
import HeaderImgAndTitle from "../../../../QuestionCard/HeaderImgAndTitle";
import HeaderItem from "../../../../QuestionCard/HeaderItem";
import Toolbar from "../../../../QuestionCard/Toolbar";
import { getUserAvatar } from "../../../../../../../lib/helper";
import { UserContext } from "../../../../../../../lib/context";

type Props = {
  post;
};

const NotificationPost: React.FC<Props> = ({ post }) => {
  const [postOpen, setPostOpen] = useState(false);
  const [question, setQuestion] = useState({} as any);

  const { koroPublicAvatar } = useContext(UserContext);

  const fetchQuestion = async () => {
    // console.log("FUNCTION ASKING QUESTION", post);
    // get question
    const docRef = doc(getFirestore(), "koroQuestions", post);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("MULLLALDLAWLD", docSnap.data());
      setQuestion({...docSnap.data(), id: docSnap.id});
    } else {
      // doc.data() will be undefined in this case
      // console.log("No such document!");
    }
  };

  useEffect(() => {
    if (postOpen) {
      fetchQuestion();
    }
  }, [postOpen]);

  const [avatarUrl, setAvatarUrl] = useState("");

  const fetchAvatar = async () => {
    const data = await getUserAvatar(question?.poster);
    // console.log(data["avatar"])
    // console.log("date", data);
    if (data == undefined) {
      setAvatarUrl(question.posterAvatar);
    }

    if(data == undefined) return;
    setAvatarUrl(data["avatar"]);
  };

  useEffect(() => {
    fetchAvatar();
  }, [koroPublicAvatar]);



  return (
    <>
    {postOpen ?   <IonIcon
        icon={chevronUp}
        onClick={() => setPostOpen(!postOpen)}
      ></IonIcon> :   <IonIcon
      icon={chevronDown}
      onClick={() => setPostOpen(!postOpen)}
    ></IonIcon> }
      {postOpen && (
        <>
          {(question?.title) && (
            <div className="koroQuestionCard ion-margin">
              <HeaderItem
                avatar={question.posterAvatar}
                name={question?.posterDisplayName}
                category={question?.category}
                publicAvatarUid={question?.poster}
                questionId={question?.id}
                isHidden={question?.hidden}
                question={question}
                refreshHandler={null}
                time={timeSince(question?.dateCreated)}
              />
              <HeaderImgAndTitle
                img={question.photo}
                title={question.title}
                body={question.body}
              />

              <Toolbar
                downvotes={question.downvotes}
                upvotes={question.upvotes}
                share="3"
                id={question.id}
                comments={4}
                question={question}
              />
              {question.status == "answered" && (
                <>
                  <IonLabel color="primary" className="ion-margin">
                    <h2 className="bold">Answer: </h2>
                  </IonLabel>
                  <IonLabel className="ion-padding">
                    <p className="ion-padding-start">{question.answer}</p>
                  </IonLabel>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NotificationPost;
