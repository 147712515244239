/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonModal,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import "./Login.css";
import { updateDoc, doc, getFirestore, setDoc } from "firebase/firestore";
import { UserContext } from "../../lib/context";

import { auth } from "../../lib/Firebase";

import { Profile } from "../../Interfaces/Profile";
import { sign } from "crypto";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { create } from "domain";
import EmailHelp from "./EmailHelp";

const Login: React.FC = () => {
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  // get affiliate code from url
  
  const [affiliateCode, setAffiliateCode] = useState<string>(new URLSearchParams(window.location.search).get("afl"));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, profile, loading, profileLoading } = useContext(UserContext);

  const [createAccountOpen, setCreateAccountOpen] = useState<boolean>(false);

  const [createEmail, setCreateEmail] = useState<string>("");
  const [createPassword, setCreatePassword] = useState<string>("");
  const [createConfirmPassword, setCreateConfirmPassword] =
    useState<string>("");

  const [loginError, setLoginError] = useState<string>("");

  const [forgotPasswordModal, setForgotPasswordModal] =
    useState<boolean>(false);

  const [createAccountError, setCreateAccountError] = useState<string>("");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [resetPasswordEmail, setResetPasswordEmail] = useState(false);
  const [error, setError] = useState("");

  const [myselfPopup, setMyselfPopup] = useState(new URLSearchParams(window.location.search).get("createAccountOpen") === "true" ? true : false);
  const [organizationPopup, setOrganizationPopup] = useState(false);

  const [loginButtonLoading, setLoginButtonLoading] = useState(false);

  // const firebaseConfig = {
  //   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  //   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  //   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  //   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  //   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  //   appId: process.env.REACT_APP_FIREBASE_APP_ID,
  //   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  // };

  // // Initialize Firebase
  // firebase.initializeApp(firebaseConfig);

  const loginSubmitted = (e: React.FormEvent<HTMLFormElement>) => {
    setLoginError("");
    e.preventDefault();
    setLoginLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setLoginLoading(false);
        // console.log(user);
        updateDoc(doc(getFirestore(), "tracking", user.uid), {
          lastSignIn: new Date(),
        });
      })
      .catch((error) => {
        setLoginLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        setLoginError(errorMessage);
        if (errorCode === "auth/wrong-password") {
          setLoginError("Wrong password or email");
        } else if (errorCode === "auth/user-not-found") {
          setLoginError("Wrong password or email");
        } else if (errorCode === "auth/invalid-email") {
          setLoginError("Invalid email");
        } else if (errorCode === "auth/missing-password") {
          setLoginError("Missing password");
        } else {
          setLoginError("Something went wrong, please try again");
        }
      });

    // const app = auth.app;
    // firebase
    //   .auth()
    //   .signInWithEmailAndPassword(email, password)
    //   .then((data) => {
    //     // Signed in
    // updateDoc(doc(getFirestore(), "tracking", data.user.uid), {
    //   lastSignIn: new Date(),
    // });
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     setLoginError(errorMessage);
    //     if (errorCode === "auth/wrong-password") {
    //       setLoginError("Wrong password or email");
    //     } else if (errorCode === "auth/user-not-found") {
    //       setLoginError("Wrong password or email");
    //     } else if (errorCode === "auth/invalid-email") {
    //       setLoginError("Invalid email");
    //     } else if (errorCode === "auth/missing-password") {
    //       setLoginError("Missing password");
    //     } else {
    //       setLoginError("Something went wrong, please try again");
    //     }
    //   });

    // signInWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     const user = userCredential.user;
    //   })
    // .catch((error) => {
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   setLoginError(errorMessage);
    //   if (errorCode === "auth/wrong-password") {
    //     setLoginError("Wrong password or email");
    //   } else if (errorCode === "auth/user-not-found") {
    //     setLoginError("Wrong password or email");
    //   } else if (errorCode === "auth/invalid-email") {
    //     setLoginError("Invalid email");
    //   } else if (errorCode === "auth/missing-password") {
    //     setLoginError("Missing password");
    //   } else {
    //     setLoginError("Something went wrong, please try again");
    //   }
    // });

    // get values from form
  };

  const createAccount = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCreateAccountError("");
    setCreateLoading(true);

    // console.log("Creating Account Function");

    if (createPassword !== createConfirmPassword) {
      setCreateAccountError("Passwords do not match");
      setCreateLoading(false);
      return;
    }

    if (validatePassword(createPassword)) {
      createUserWithEmailAndPassword(auth, createEmail, createPassword)
        .then((userCredential) => {
          const user = userCredential.user;
          // add doc to users collection
          // console.log("User Created!!");

          const profile: Profile = {
            uid: user.uid,
            email: user.email,
            dateCreated: new Date(),
            access: "fullAccess",
            affiliateCode: affiliateCode,
            tempPasswordChanged: true,
            hasTelemedicine: false,
            hraComplete: false,
            groupName: process.env.REACT_APP_SA_GROUP_NAME,
            loseWeight: null,
            copd: null,
            addr2: "",
            id: "",
            sleepSatisfaction: "",
            glucoseTile: false,
            compliant: false,
            dietSatisfaction: "",
            quitSmoking: null,
            asthma: null,
            lastName: "",
            coach: process.env.REACT_APP_SA_COACH,
            depression: null,
            congestiveHeartFailure: null,
            hasQuestis: true,
            accountSetupComplete: null,
            state: "",
            getBetterSleep: null,
            bloodPressureMeds: "",
            hasFacescan: true,
            smsNotificationsOn: true,
            smoker: "",
            externalID: user.uid,
            dobTimeStamp: null,
            walmartHealthPlanCode: process.env.REACT_APP_SA_GROUP_WHVC_CODE,
            diabetes: "",
            dob: "",
            improveMentalHealth: null,
            weight: null,
            zip: "",
            activitySatisfaction: "",
            eatBetter: null,
            hypertension: "",
            heightIn: null,
            stressLevelSatisfaction: "",
            gender: "",
            active: true,
            addr1: "",
            phone: "",
            subId: "",
            increaseActivity: null,
            interest: [],
            groupID: process.env.REACT_APP_SA_GROUP_ID,
            cometChatUid: user.uid.toLowerCase(),
            city: "",
            manageStress: null,
            compliantSetDate: null,
            arrhythmia: null,
            complianceHistory: [],
            koroWelcomeComplete: false,
            photoURL:
              "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/default.png?alt=media&token=c8450ca6-7ef6-49a3-95ff-2246452d62cc",
            anxiety: null,
            WellnessFeelingDown: "",
            role: "member",
            hasCoaching: true,
            firstName: "",
            backpain: null,
            heightFt: null,
            hraCompleteDate: null,
            accountType: "individual",
            subscriptionType: "freeTrial",
          };
          // console.log("profile", profile);
          setDoc(doc(getFirestore(), "users", user.uid), profile)
            .then(() => {
              // console.log("User added to database");
              setCreateAccountOpen(false);
              setCreateLoading(false);
            })
            .catch((error) => {
              // console.log("Error adding user to database");
              console.log(error);
              setCreateAccountOpen(false);
              setCreateLoading(false);
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setCreateLoading(false);
          setCreateAccountError(errorMessage);
          if (errorCode === "auth/email-already-in-use") {
            setCreateAccountError("Email already in use");
          } else if (errorCode === "auth/invalid-email") {
            setCreateAccountError("Invalid email");
          } else if (errorCode === "auth/weak-password") {
            setCreateAccountError("Weak password");
          } else {
            setCreateAccountError("Something went wrong, please try again");
          }
        });
    } else {
      console.log("Error");
      setCreateLoading(false);
    }
  };

  const validatePassword = (pass) => {
    //pasword regex
    var regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (pass === undefined || pass === "") {
      setCreateAccountError("Password is required");
      return false;
    } else if (pass.length < 8) {
      setCreateAccountError("Password must be at least 8 characters");
      return false;
    } else if (!regex.test(pass)) {
      setCreateAccountError(
        "Password must contain a number and a special character"
      );
      return false;
    } else {
      setCreateAccountError("");
      return true;
    }
  };

  const forgetPasswordLink = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // send foreget password email firebase
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        alert("Password reset email sent!");
        setForgotPasswordModal(false);
      })
      .catch((error) => {
        // Error sending password reset email
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  useEffect(() => {
    // console.log("PRofile loading", profileLoading);
  }, [profileLoading]);



  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="middle">
          <div className="center-container">
            {loading ? (
              <>
                <IonSpinner></IonSpinner>{" "}
                <IonLabel>
                  <p>It appears we are having some trouble...</p>
                </IonLabel>
              </>
            ) : (
              <>
                <form onSubmit={(e) => loginSubmitted(e)}>
                  <div className="center-container">
                    <img
                      src="/assets/img/MainLogo.png"
                      className="loginLogo"
                      alt="logo"
                    />
                  </div>

                  <div className="inputItem">
                    <IonInput
                      type="email"
                      name="email"
                      placeholder="Email"
                      id="email"
                      value={email}
                      required={true}
                      onIonInput={(e: any) => setEmail(e.target.value)}
                    ></IonInput>
                  </div>
                  <div className="inputItem">
                    <IonInput
                      type="password"
                      name="email"
                      placeholder="Password"
                      id="email"
                      value={password}
                      required={true}
                      onIonInput={(e: any) => setPassword(e.target.value)}
                    ></IonInput>
                  </div>
                  <p className="loginError">{loginError}</p>
                  <IonButton
                    expand="block"
                    className="ion-margin"
                    type="submit"
                  >
                    {loginLoading ? <IonSpinner name="lines-small" /> : "Login"}
                  </IonButton>
                  <IonButton
                    expand="block"
                    fill="outline"
                    className="ion-margin-horizontal"
                    onClick={() => setCreateAccountOpen(true)}
                  >
                    Create Account
                  </IonButton>
                  <p
                    className="forgotPasswordLink"
                    onClick={() => setForgotPasswordModal(true)}
                  >
                    Forgot Password?
                  </p>
                </form>
              </>
            )}
          </div>
          <IonModal isOpen={forgotPasswordModal}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Forgot Password</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setForgotPasswordModal(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="ionPaddingTopLarge">
                <div className="center-container">
                  <form onSubmit={(e) => forgetPasswordLink(e)}>
                    <div className="center-container">
                      <img
                        src="/assets/img/MainLogo.png"
                        className="loginLogo"
                        alt="logo"
                      />
                    </div>
                    <div className="inputItem">
                      <IonInput
                        required={true}
                        type="email"
                        name="email"
                        placeholder="Email"
                        id="email"
                        value={email}
                        onIonInput={(e: any) => setEmail(e.target.value)}
                      ></IonInput>
                    </div>
                    <p className="loginError">{createAccountError}</p>
                    <IonButton
                      expand="block"
                      className="ion-margin"
                      type="submit"
                    >
                      Get Reset Password Link
                    </IonButton>
                  </form>
                </div>
              </div>
            </IonContent>
          </IonModal>
          <IonModal isOpen={createAccountOpen}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Create Account</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setCreateAccountOpen(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="ion-margin ion-padding">
                <IonLabel>
                  <h1>Is Allied Wellness covered by your employer or health plan?</h1>
                  <p>
                    Many members join Allied Wellness as part of their
                    employer/organization benefits or through a sponsoring
                    health provider.
                  </p>
                </IonLabel>
                <div className="ionPaddingTopLarge">
                  <IonButton
                    shape="round"
                    expand="full"
                    className="ion-margin-top"
                    onClick={() => setOrganizationPopup(true)}
                  >
                    Yes
                  </IonButton>
                  <IonButton
                    shape="round"
                    expand="full"
                    onClick={() => setMyselfPopup(true)}
                  >
                    No
                  </IonButton>
                </div>
              </div>

              {/* 
                <div className="ionPaddingTopLarge">
                <div className="center-container">
                  <form onSubmit={(e) => createAccount(e)}>
                    <div className="center-container">
                      <img
                        src="/assets/img/MainLogo.png"
                        className="loginLogo"
                        alt="logo"
                      />
                    </div>
                    <div className="inputItem">
                      <IonInput
                        required={true}
                        type="email"
                        name="email"
                        placeholder="Email"
                        id="email"
                        value={createEmail}
                        onIonInput={(e: any) => setCreateEmail(e.target.value)}
                      ></IonInput>
                    </div>
                    <div className="inputItem">
                      <IonInput
                        required={true}
                        type="password"
                        name="password"
                        placeholder="Password"
                        id="password"
                        value={createPassword}
                        onIonInput={(e: any) =>
                          setCreatePassword(e.target.value)
                        }
                      ></IonInput>
                    </div>
                    <div className="inputItem">
                      <IonInput
                        required={true}
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        value={createConfirmPassword}
                        onIonInput={(e: any) =>
                          setCreateConfirmPassword(e.target.value)
                        }
                      ></IonInput>
                    </div>
                    <p className="loginError ion-text-center">
                      {createAccountError}
                    </p>
                    <IonButton
                      expand="block"
                      className="ion-margin"
                      type="submit"
                    >
                      Create
                    </IonButton>
                  </form>
              </div>

                </div> */}
            </IonContent>
          </IonModal>

          <IonModal
            isOpen={myselfPopup}
            onDidDismiss={() => setMyselfPopup(false)}
          >
            <IonHeader>
              <IonToolbar>
                <IonTitle>Create Account</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setMyselfPopup(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="ionPaddingTopLarge">
                <div className="center-container">
                  <form onSubmit={(e) => createAccount(e)}>
                    <div className="center-container">
                      <img
                        src="/assets/img/MainLogo.png"
                        className="loginLogo"
                        alt="logo"
                      />
                    </div>
                    <div className="inputItem">
                      <IonInput
                        required={true}
                        type="email"
                        name="email"
                        placeholder="Email"
                        id="email"
                        value={createEmail}
                        onIonInput={(e: any) => setCreateEmail(e.target.value)}
                      ></IonInput>
                    </div>
                    <div className="inputItem">
                      <IonInput
                        required={true}
                        type="password"
                        name="password"
                        placeholder="Password"
                        id="password"
                        value={createPassword}
                        onIonInput={(e: any) =>
                          setCreatePassword(e.target.value)
                        }
                      ></IonInput>
                    </div>
                    <div className="inputItem">
                      <IonInput
                        required={true}
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        value={createConfirmPassword}
                        onIonInput={(e: any) =>
                          setCreateConfirmPassword(e.target.value)
                        }
                      ></IonInput>
                    </div>
                    <p className="loginError ion-text-center">
                      {createAccountError}
                    </p>
                    <IonButton
                      expand="block"
                      className="ion-margin"
                      type="submit"
                    >
                      {createLoading ? (
                        <IonSpinner name="lines-small" />
                      ) : (
                        "Create"
                      )}
                    </IonButton>
                  </form>
                </div>
              </div>
            </IonContent>
          </IonModal>
          <IonModal
            isOpen={organizationPopup}
            onDidDismiss={() => setOrganizationPopup(false)}
          >
            <IonHeader>
              <IonToolbar>
                <IonTitle>Organization</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setOrganizationPopup(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="ionPaddingTopLarge">
                <div className="center-container">
                  <IonLabel>
                    <h1>Organization/Employer Access</h1>
                  </IonLabel>
                  <IonLabel className="ion-margin ion-text-center">
                    <p>
                      If you are supposed to have Allied Wellness coverage
                      through an employer or organization, you should have
                      received an email from <a>no_reply@alliedwellness.com </a>
                      with your login information.
                    </p>
                  </IonLabel>
                </div>
                <IonButton
                  shape="round"
                  expand="block"
                  className="ion-margin"
                  onClick={() => {
                    setOrganizationPopup(false);
                  }}
                >
                  Back to Log In
                </IonButton>
                <IonLabel className="ion-margin ion-text-center">
                  <p>
                    Can't find your welcome email? Click <EmailHelp />
                  </p>
                </IonLabel>
              </div>
            </IonContent>
          </IonModal>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
