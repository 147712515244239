import { IonAvatar, IonLabel, IonRouterLink } from "@ionic/react";
import "./index.css";
import { ClinicalConcernsList } from "../../../Interfaces/ClinicalConcerns";
import InterestTile from "../ItemTile";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper";
import { useContext } from "react";
import { UserContext } from "../../../../../lib/context";

type Props = {
  refresh;
  focus;
};

const ClinicalConcernSlider: React.FC<Props> = ({ refresh, focus }) => {
  const { koroPublicAvatar } = useContext(UserContext);


  if (focus == "all") {
    return (
      <div className="on-padding-start ion-margin">
        <Swiper spaceBetween={10}
          slidesPerView={2.5}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
          >
          {ClinicalConcernsList.map((clinicalConcern, index) => {
            return (
              <SwiperSlide key={clinicalConcern.title}>
                <InterestTile item={clinicalConcern} refresh={refresh} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  }

  if (koroPublicAvatar.koroClinicalConcerns == null) return (<><IonLabel><p className="ion-margin-start ion-padding-start">Post a question to start growing your Clinical Concerns</p></IonLabel></>);

  // return tiles that only match with koroPublicAvatar.koroInterest
  if (focus == "focus" && koroPublicAvatar.koroClinicalConcerns) {
    return (
      <div className="on-padding-start ion-margin">
        <Swiper spaceBetween={10}
          slidesPerView={2.5}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
          >
          {ClinicalConcernsList.map((clinicalConcern, index) => {
            if (koroPublicAvatar.koroClinicalConcerns.includes(clinicalConcern.id)) {
              return (
                <SwiperSlide key={clinicalConcern.title}>
                  <InterestTile item={clinicalConcern} refresh={refresh} />
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </div>
    );
  }

  return <></>;

};

export default ClinicalConcernSlider;
