import React from "react";
import "../Popup.css";

const Popup_breathing = () => {
  return (
    <div className="ion-padding popup-wrapper">
      <p>
        The normal resting breathing rate for adults is between 12 and 25
        breaths per minute. A slower breathing rate may be caused by certain
        medications or suggest an underlying medical condition, for example,
        hypothyroidism, heart condition, electrolyte imbalance, etc. A faster
        breathing rate could have many causes including walking fast or
        exercise, asthma, anxiety, infection, or cardiovascular condition. You
        may want to consult your doctor if your breathing rate falls outside of
        the normal range.
      </p>
    </div>
  );
};

export default Popup_breathing;
