import {
  doc,
  onSnapshot,
  getFirestore,
  collection,
  getDocs,
} from "firebase/firestore";
import { auth, firestore } from "./Firebase";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { signInWithEmailAndPassword } from "firebase/auth";

// Custom hook to read  auth record and user profile doc
export function useUserData() {
  // create profile interface to use in the hook

  const [user, loading, error] = useAuthState(auth);
  const [profile, setProfile] = useState<any | null>(null);
  const [koroPublicAvatar, setKoroPublicAvatar] = useState<any | null>(null);
  const [profileLoading, setProfileLoading] = useState<boolean>(false);


  useEffect(() => {
    // turn off realtime subscription
    let unsubscribe;
    setProfileLoading(true);


    // get all documents from users collection
    if (user) {

      const ref = doc(getFirestore(), "users", user.uid);

      unsubscribe = onSnapshot(ref, (doc) => {
        setProfile({ id: doc.id, ...doc.data() });

        setProfileLoading(false);
      });


    } else {
      setProfileLoading(false);
      setProfile(null);
      setProfileLoading(false);
    }

    return unsubscribe;
  }, [user]);

  useEffect(() => {
    // turn off realtime subscription
    let unsubscribe;

    // get all documents from users collection
    if (user) {

      const ref = doc(getFirestore(), "koroPublicAvatars", user.uid);

      unsubscribe = onSnapshot(ref, (doc) => {
        if (doc.exists()) {
          setKoroPublicAvatar({ id: doc.id, ...doc.data() });
        } else {
          setKoroPublicAvatar(null);
        }
      });


    } else {

      setKoroPublicAvatar(null);
    }

    return unsubscribe;
  }, [user]);

  return { user, profile, loading, error, koroPublicAvatar, profileLoading };

}

