/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import React, { useEffect, useState } from "react";
import {
  query,
  collection,
  getFirestore,
  orderBy,
  getDocs,
  where,
} from "firebase/firestore";
import { Browser } from "@capacitor/browser";

const ViewMorePage = ({ title, id }) => {
  // const { user, profile } = useContext(UserContext);
  const [data, setData] = useState([]);

  const openBrowser = async (url) => {
    await Browser.open({ url: url });
  };

  const getPost = async () => {
    if (id === "all") {
      var q = query(
        collection(getFirestore(), "content"),
        where("isActive", "==", true),
        orderBy("date", "desc")
      );
    } else {
      var q = query(
        collection(getFirestore(), "content"),
        where("catagoryList", "array-contains", id),
        where("isActive", "==", true),
        orderBy("date", "desc")
      );
    }
    var post = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      post.push(doc.data());
    });

    setData(post);
  };

  useEffect(() => {
    getPost();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="mainHeaderBackground">
          <IonButtons slot="start" className="mainPageTitleTEst">
            <IonBackButton className="mainPageTitleTEst" defaultHref="tabs/explore" />
          </IonButtons>
          <IonTitle className="mainPageTitleTEst">{title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonList>
          {data.map((content) => (
            <IonItem key={content.id}>
              <IonThumbnail slot="start">
                <img src={content.photo} />
              </IonThumbnail>
              <IonLabel>
                <h2 className="ion-text-wrap">{content.title}</h2>
                <p>{content.type} {content.type === "Video" ? <>{content.length} minutes</> : <></>} {content.tags ? <>{content.tags.includes("recipe") ? <>{content.calories} cals</> : null }</> : null }</p>
              </IonLabel>
              <IonButton
                fill="outline"
                onClick={() => openBrowser(content.link)}
                slot="end"
              >
                View
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ViewMorePage;
