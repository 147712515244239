import {
  IonAvatar,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRouterLink,
} from "@ionic/react";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import "firebase/compat/auth";
import { callOutline, informationCircle } from "ionicons/icons";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
} from "firebase/firestore";
import { UserContext } from "../../lib/context";
import StartTimePretty from "./StartTimePretty";

import "./Session.css";

type Props = { refresh };

const RenderSessionUpcoming: React.FC<Props> = ({ refresh }) => {
  const [appointment, setAppointment] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    // console.log("Retrieve Data on initial load");
    fetchSessions();
  }, []);

  useEffect(() => {
    if (refresh) {
      // console.log("Retrieve Data on refresh");
      fetchSessions();
    }
  }, [refresh]);

  const openSession = (sessionLink) => {
    window.open(sessionLink, "_blank");
  };
  const openSessionDetails = (sessionID) => {
    window.open("/session/" + sessionID, "_self");
  };

  const fetchSessions = async () => {
    //get sessiosn from firebase
    const q = query(
      collection(getFirestore(), "sessions"),
      where("memberEmail", "==", user.email)
    );

    var sessions = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      sessions.push({ id: doc.id, ...doc.data() });
    });


    setAppointment(sessions);
  };

  const ShowCorrectTime = (session) => {
    //2020-04-13T00:00:00.000+08:00
    //2023-01-31T09:00:00.000+06:00
    const firstPart = session.startTime.split("T")[0];
    const secondPart = session.startTime.split("T")[1].split("-")[0];
    const gmt = session.startTime.split("T")[1].split("-")[1];

    // console.log("firstPart", firstPart)
    // console.log("secondPart", secondPart)
    // console.log("gmt", gmt)
    const newString = firstPart + "T" + secondPart + ".000+" + gmt;
    // console.log("newString", newString);
    // console.log("gmt", gmt);
    const sessionStartTime = new Date(session.startTime);
    // console.log("sessionStartTime", sessionStartTime);
    // console.log(session.startTime+"+"+gmt)
    // console.log("sessionStartTime", sessionStartTime)
    // console.log("sessionStartTime", sessionStartTime);
  };

  const ShowSessions = () => {
 
    
    let sessions = [];
    appointment.forEach((session, index) => {
      var sessionDate = new Date(session.startTime);
      var today = new Date();

      // add 2 hours to sessionDate
      sessionDate.setHours(sessionDate.getHours() + 2);

      if (sessionDate >= today && session.status !== "cancelled") {
        sessions.push(
          <IonItem
            className="session session-item"
            key={session.id}
            lines="none"
            color="primary"
            routerLink={"/session/" + session.id}
            detail={false}
          >
            {session.coachPhoto && (
              <IonAvatar slot="start">
                <img src={session.coachPhoto} alt="coach" />
              </IonAvatar>
            )}
            <IonIcon
              icon={informationCircle}
              // onClick={() => openSessionDetails(session.id)}
              slot="end"
            ></IonIcon>{" "}
            <IonLabel class="ion-text-center ion-text-wrap">
              {" "}
              <StartTimePretty startTime={session.startTime} />
            </IonLabel>{" "}
          </IonItem>
        );
      }
    });

    if (sessions.length === 0) {
      return <div>No Upcoming Appointment</div>;
    }

    return (
      <>
        <IonList>{sessions}</IonList>
      </>
    );
  };

  return (
    <>
      <ShowSessions />
    </>
  );
};

export default RenderSessionUpcoming;
