import { Route } from "react-router-dom";
import { IonButton, IonPage, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import LoginPage from "./Login";

setupIonicReact();

const PublicRouter = () => {

  const directBackToApp = () => {
    window.location.href = "";
  };

  const ThankYou = () => {
    return (
      <IonPage>
        <h1>Thank you for your submission!</h1>
        <IonButton onClick={directBackToApp}>Back to App</IonButton>
      </IonPage>
    );

  };

  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/thankyou">
          <ThankYou />
        </Route>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <Route render={() => <LoginPage />} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default PublicRouter;
