import {
  IonLabel,
} from "@ionic/react";
import "./style.css";
import HeaderItem from "./HeaderItem";
import HeaderImgAndTitle from "./HeaderImgAndTitle";
import Toolbar from "./Toolbar";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../lib/context";

import { timeSince } from "../../../../lib/Helpers/time";
import { getUserAvatar } from "../../../../lib/helper";

type Props = { question; refresh; refreshHandler };

const QuestionCard: React.FC<Props> = ({
  question,
  refresh,
  refreshHandler,
}) => {
  const { koroPublicAvatar } = useContext(UserContext);
  const [avatarUrl, setAvatarUrl] = useState("");

  const fetchAvatar = async () => {
    const data = await getUserAvatar(question?.poster);
    // console.log(data["avatar"])
    // console.log("date", data);
    if (data == undefined) {
      setAvatarUrl(question.posterAvatar);
    }
    if(data == undefined) return;

    setAvatarUrl(data["avatar"]);
  };

  useEffect(() => {
    fetchAvatar();
  }, [koroPublicAvatar]);

  useEffect(() => {
    fetchAvatar();
  }, [refresh]);

  return (
    <>
      <div className="koroQuestionCard ion-margin ">
        <HeaderItem
          avatar={avatarUrl}
          name={question.posterDisplayName}
          category={question.category}
          publicAvatarUid={question.poster}
          questionId={question.id}
          isHidden={question.hidden}
          question={question}
          refreshHandler={refreshHandler}
          time={timeSince(question.dateCreated)}
        />
        <HeaderImgAndTitle
          img={question.photo}
          title={question.title}
          body={question.body}
        />
        <Toolbar
          downvotes={question.downvotes}
          upvotes={question.upvotes}
          share="3"
          id={question.id}
          comments={10}
          question={question}
        />
        {question.status == "answered" && (
          <>
            <IonLabel color="primary" className="ion-margin">
              <h2 className="bold">Answer: </h2>
            </IonLabel>
            <IonLabel className="ion-padding">
              <p className="ion-padding-start ion-padding-end">{question.answer}</p>
            </IonLabel>
          </>
        )}
      </div>
    </>
  );
};

export default QuestionCard;
