import {
    IonAvatar,
    IonCard,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRouterLink,
    IonRow,
  } from "@ionic/react";
  //import spinner
  import React, { useContext, useEffect, useState } from "react";
  import "firebase/compat/auth";
  import { callOutline, informationCircle } from "ionicons/icons";
  import {
    query,
    collection,
    getFirestore,
    where,
    getDocs,
  } from "firebase/firestore";
  import { UserContext } from "../../lib/context";
import GoalsDaySuccessGraph from "./GoalsDaySuccessGraph";
import { count } from "console";

import "./GoalsWeeklyDisplay.css";
  
  
  type Props = { goals };
  
  const GoalsWeeklyDisplay: React.FC<Props> = ({ goals }) => {


    // function to get the date of monday, tuesday, etc of this week
    const getDayOfWeek = (date) => {
        const dayOfWeek = new Date(date).getDay();
        return isNaN(dayOfWeek) ? null : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeek];
    }

    const getMonday = (d) => {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    // get past most recetn sunday in the past
    const getSunday = (d) => {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day; // adjust when day is sunday
        return new Date(d.setDate(diff));
    }



    const sunday = getSunday(new Date());

    const monday =  new Date(sunday);
    monday.setDate(sunday.getDate() + 1);

    const tuesday =  new Date(sunday);
    tuesday.setDate(sunday.getDate() + 2);

    const wednesday =  new Date(sunday);

    wednesday.setDate(sunday.getDate() + 3);

    const thursday =  new Date(sunday);

    thursday.setDate(sunday.getDate() + 4);

    const friday =  new Date(sunday);

    friday.setDate(sunday.getDate() + 5);

    const saturday =  new Date(sunday);

    saturday.setDate(sunday.getDate() + 6);





    const getDaySuccess = (date: Date) => {
        // start with daily goals
        // get date format of mm/dd/yyyy
        const dateFormatted = (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();
        // remove 0s from the front of the date
        const dateFormattedNoZeros = dateFormatted.replace(/^0+/, '');

        var completed = 0;
        var total = 0;

        // see if date is today
        const today = new Date();
        const todayFormatted = (today.getMonth() + 1) + '/' + today.getDate() + '/' +  today.getFullYear();
        const todayFormattedNoZeros = todayFormatted.replace(/^0+/, '');

        if (dateFormattedNoZeros === todayFormattedNoZeros) {

            goals.forEach(goal => {
                if (goal.freq === 'daily') {
                    // look through tracking array
                    if (goal.completed) {
                        completed++;
                    }
                    total++;
                }
        })
        return completed / total * 100;
    }



        // lets look for this date in the goals array

        // loop through goals array
        goals.forEach(goal => {
            if (goal.freq === 'daily') {
                // look through tracking array
                if(goal.tracking !== undefined){
                    goal.tracking.forEach(tracking => {
                        if (tracking[dateFormattedNoZeros] !== undefined) {
                            if (tracking[dateFormattedNoZeros]) {
                                completed++;
                            }
                            total++;
                        }
                    })
                }
               
            }

        })

        // console.log('completed: ' + completed)
        // console.log('total: ' + total)

        if(total === 0){
            return 0;
        }
        return completed / total * 100;
        
    }
  
  
    return (
      <>
        <IonGrid>
            <IonRow className='ion-padding-horizontal'>
                <IonCol class="ion-no-padding">
                    S
                    <br></br>
                    <GoalsDaySuccessGraph dateNumber={sunday.getDate()} success={getDaySuccess(sunday)}/>
                </IonCol >
                <IonCol class="ion-no-padding">
                    M
                    <br></br>
                    <GoalsDaySuccessGraph dateNumber={monday.getDate()} success={getDaySuccess(monday)}/>
                </IonCol>
                <IonCol class="ion-no-padding">
                    T
                    <br></br>
                    <GoalsDaySuccessGraph dateNumber={tuesday.getDate()} success={getDaySuccess(tuesday)}/>
                </IonCol>
                <IonCol class="ion-no-padding">
                    W
                    <br></br>
                    <GoalsDaySuccessGraph dateNumber={wednesday.getDate()} success={getDaySuccess(wednesday)}/>
                </IonCol>
                <IonCol class="ion-no-padding">
                    T
                    <br></br>
                    <GoalsDaySuccessGraph dateNumber={thursday.getDate()} success={getDaySuccess(thursday)}/>
                </IonCol>
                <IonCol class="ion-no-padding">
                    F
                    <br></br>
                    <GoalsDaySuccessGraph dateNumber={friday.getDate()} success={getDaySuccess(friday)}/>
                </IonCol>
                <IonCol class="ion-no-padding">
                    S
                    <br></br>
                    <GoalsDaySuccessGraph dateNumber={saturday.getDate()} success={getDaySuccess(saturday)}/>
                </IonCol>
            </IonRow>
        </IonGrid>
      </>
    );
  };
  
  export default GoalsWeeklyDisplay;