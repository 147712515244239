import React from "react";
import "../Popup.css";

const Popup_HypercholesterolemiaRisk = () => {
  return (
    <div className="ion-padding popup-wrapper">
      <p>
        Hypercholesterolemia risk corresponds to the percentage of people with
        the user's risk profile who have abnormally high cholesterol levels
        (defined as a total cholesterol (TC)-to-high density lipoprotein (HDL)
        cholesterol (“good cholesterol”) ratio of 4.3 or higher).
      </p>
      <p>
        This risk profile is based on their facial blood flow and demographic
        information.
      </p>
      <p>
        Hypercholesterolemia is a treatable risk factor for cardiovascular
        disease. Therefore, those with a high hypercholesterolemia risk should
        talk to their doctor about having their HDL and total cholesterol levels
        checked with a blood test.
      </p>
      <p>
        <strong>Notice</strong>: Moment-to-moment fluctuations in your
        physiology mean that your risk estimates could vary to some degree from
        one measurement to the next. The best estimate of your overall risk is
        therefore obtained by averaging several measurements throughout the day
        and across several days to adequately account for this physiological
        variation.
      </p>
    </div>
  );
};

export default Popup_HypercholesterolemiaRisk;
