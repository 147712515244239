/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
//import spinner
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../../lib/context";
import Loader from "../../Loaderv2";

const ScanList: React.FC = () => {
  const { user } = useContext(UserContext);
  const [scans, setScans] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const GetScanList = async () => {
    axios
      .post(
        `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/DFX-GetMeasurementList`,
        { uid: user.uid }
      )
      .then((res) => {
        setScans(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDateFromTimestamp = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      getHoursFrom24HourTime(date.getHours()) +
      ":" +
      date.getMinutes() +
      " " +
      getPmorAm(date.getHours())
    );
  };

  useEffect(() => {
    GetScanList();
  }, []);

  //get 12 hour time from timestamp
  const getHoursFrom24HourTime = (hour: number) => {
    if (hour > 12) {
      return hour - 12;
    } else if (hour === 0) {
      return 12;
    } else {
      return hour;
    }
  };

  const getPmorAm = (hour: number) => {
    if (hour === 24) {
      return "AM";
    } else if (hour > 11) {
      return "PM";
    } else {
      return "AM";
    }
  };

  const renderScanItem = (scan: any, index: any) => {
    if (scan.StatusID === "COMPLETE") {
      return (
        <>
          <IonItem key={index}>
            <IonLabel>{getDateFromTimestamp(scan.Created)}</IonLabel>
            <IonButton slot="end"  routerLink={"scan/" + scan.ID}>
              View
            </IonButton>
          </IonItem>
        </>
      );
    } else {
      return;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="diagnostics" />
          </IonButtons>

          <IonTitle className="mainPageTitle">Scan List</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className=" ion-text-center">
        <IonList lines="none">
          {loading ? (
            <Loader />
          ) : (
            scans.map((scan, index) => renderScanItem(scan, index))
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ScanList;
