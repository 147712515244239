import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from "@ionic/react";
import "./style.css";
import { useContext, useState } from "react";
import Toolbar from "../../QuestionCard/Toolbar";
import { timeSince } from "../../../../../lib/Helpers/time";
import HeaderImgAndTitle from "../../QuestionCard/HeaderImgAndTitle";
import HeaderItem from "../../QuestionCard/HeaderItem";
import { UserContext } from "../../../../../lib/context";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

type Props = {
  question;
};

const Popup: React.FC<Props> = ({ question }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { koroPublicAvatar } = useContext(UserContext);

  const [answered, setAnswered] = useState("");
  const [goneInstead, setGoneInstead] = useState("Primary Care Doctor");

  const [speakToExpert, setSpeakToExpert] = useState("");

  const [useFeatureAgain, setUseFeatureAgain] = useState("");

  const [recommendFeature, setRecommendFeature] = useState("");

  const [whyNotRecommend, setWhyNotRecommend] = useState("");

  const [feedback, setFeedback] = useState("");

  const [rating, setRating] = useState(1);
  const [hover, setHover] = useState(0);

  const [speedRating, setSpeedRating] = useState(1);
  const [speedHover, setSpeedHover] = useState(0);

  const ResponseStarRating = () => {
    return (
      <div className="star-rating-view">
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={index <= (hover || rating) ? "on star" : "off star"}
              onClick={() => setRating(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}
      </div>
    );
  };

  const SpeedStarRating = () => {
    return (
      <div className="star-rating-view">
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={
                index <= (speedHover || speedRating) ? "on star" : "off star"
              }
              onClick={() => setSpeedRating(index)}
              onMouseEnter={() => setSpeedHover(index)}
              onMouseLeave={() => setSpeedHover(speedRating)}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}
      </div>
    );
  };

  const submitResponse = () => {
    var data = {};

    if (answered == "yes") {
      data = {
        answer: question.attemptedAnswer,
        answerCoach: question.attemptedAnswerCoach,
        answerDate: question.attemptedDateAnswer,
        questionSuccessRating: rating,
        goneInstead: goneInstead,
        status: "answered",
        speedRating: speedRating,
        speakToExpert: speakToExpert,
        useFeatureAgain: useFeatureAgain,
        recommendFeature: recommendFeature,
        whyNotRecommend: whyNotRecommend,
        feedback: feedback,
        responseSubmittedDate: new Date(),
      };
    } else {
      data = {
        status: "waitingForAnswer",
        attemptedAnswer: null,
        attemptedAnswerCoach: null,
        attemptedDateAnswer: null,
      };
    }

    // console log data size in bytes
    const newDoc = question;
    const newData = { ...newDoc, ...data };
    var json = JSON.stringify(newData);
    // console.log("json", json);
    var bytes = new Blob([json]).size;
    // console.log("bytes", bytes);

    updateDoc(doc(getFirestore(), "koroQuestions", question.id), { ...data })
      .then(() => {
        // console.log("Document successfully updated!");
        setIsOpen(false);
      })
      .catch((error) => {
        console.log("Error updating document: ", error);
      });

    setIsOpen(false);
  };

  return (
    <>
      <IonModal isOpen={isOpen} backdropDismiss={false}>
        <IonHeader>
          <IonToolbar>
            <IonTitle></IonTitle>
            <IonButtons slot="start">
              <IonButton
                disabled={answered == ""}
                onClick={() => submitResponse()}
              >
                Submit
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="backgroundColor ion-padding">
          <div className="koroQuestionCard ion-margin ion-padding-bottom ion-padding">
            <HeaderItem
              avatar={koroPublicAvatar.avatar}
              name={question.posterDisplayName}
              category={question.category}
              publicAvatarUid={question.poster}
              questionId={question.id}
              isHidden={question.hidden}
              question={question}
              refreshHandler={null}
              time={timeSince(question.dateCreated)}
            />
            <HeaderImgAndTitle
              img={question.photo}
              title={question.title}
              body={question.body}
            />

            <IonLabel color="primary" className="ion-margin">
              <h2 className="bold">Answer: </h2>
            </IonLabel>
            <IonLabel className="ion-padding">
              <p className="ion-padding-start">{question.attemptedAnswer}</p>
            </IonLabel>
          </div>
          <IonLabel className="ion-text-center">
            <h2 className="bolding">
              Did this response answer your question(s)? {answered}
            </h2>
          </IonLabel>
          <IonRadioGroup
            value={answered}
            onIonChange={(e) => setAnswered(e.detail.value)}
          >
            <IonItem
              lines="none"
              className="ion-text-center itemBackgroundFix"
              onClick={() => setAnswered("yes")}
            >
              <IonLabel
                color={answered == "yes" ? "primary" : ""}
                className={answered == "yes" ? "bold" : ""}
              >
                Yes
              </IonLabel>
            </IonItem>
            <IonItem
              lines="none"
              className="ion-text-center radio-no-background itemBackgroundFix"
              onClick={() => setAnswered("no")}
            >
              <IonLabel
                color={answered == "no" ? "primary" : ""}
                className={answered == "no" ? "bold" : ""}
              >
                No
              </IonLabel>
            </IonItem>
          </IonRadioGroup>
          {answered == "yes" && (
            <>
              <IonLabel className="ion-text-center">
                <h2 className="bolding">
                  If you didn’t get this answer here, where would you have gone
                  instead?
                </h2>
              </IonLabel>
              <div className="center">
                <IonItem
                  lines="none"
                  className="ion-text-center center itemBackgroundFix"
                >
                  <IonSelect
                    value={goneInstead}
                    onIonChange={(e) => setGoneInstead(e.detail.value)}
                  >
                    <IonSelectOption value="Primary Care Doctor">
                      Primary Care Doctor
                    </IonSelectOption>
                    <IonSelectOption value="Urgent Care">
                      Urgent Care
                    </IonSelectOption>
                    <IonSelectOption value="Specialist">
                      Specialist
                    </IonSelectOption>
                    <IonSelectOption value="Emergency Room">
                      Emergency Room
                    </IonSelectOption>
                    <IonSelectOption value="Nowhere">Nowhere</IonSelectOption>
                    <IonSelectOption value="Other">Other</IonSelectOption>
                    <IonSelectOption value="N/A">N/A</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </div>
              <IonLabel className="ion-text-center">
                <h2 className="bolding">How would you rate this response?</h2>
              </IonLabel>
              <div className="center">
                <ResponseStarRating />
              </div>
              <IonLabel className="ion-text-center">
                <h2 className="bolding">
                  How would you rate the speed of this response?
                </h2>
              </IonLabel>
              <div className="center">
                <SpeedStarRating />
              </div>
              <IonLabel className="ion-text-center ">
                <h2 className="bolding">
                  Would you like to speak with the expert who answered your
                  question?
                </h2>
              </IonLabel>
              <IonRadioGroup
                value={speakToExpert}
                onIonChange={(e) => setSpeakToExpert(e.detail.value)}
              >
                <IonItem
                  lines="none"
                  className="ion-text-center itemBackgroundFix"
                  onClick={() => setSpeakToExpert("yes")}
                >
                  <IonLabel
                    color={speakToExpert == "yes" ? "primary" : ""}
                    className={speakToExpert == "yes" ? "bold" : ""}
                  >
                    Yes
                  </IonLabel>
                </IonItem>
                <IonItem
                  lines="none"
                  className="ion-text-center radio-no-background itemBackgroundFix"
                  onClick={() => setSpeakToExpert("no")}
                >
                  <IonLabel
                    color={speakToExpert == "no" ? "primary" : ""}
                    className={speakToExpert == "no" ? "bold" : ""}
                  >
                    No
                  </IonLabel>
                </IonItem>
              </IonRadioGroup>
              <IonLabel className="ion-text-center">
                <h2 className="bolding">Would you use this feature again?</h2>
              </IonLabel>
              <IonRadioGroup
                value={useFeatureAgain}
                onIonChange={(e) => setUseFeatureAgain(e.detail.value)}
              >
                <IonItem
                  lines="none"
                  className="ion-text-center itemBackgroundFix"
                  onClick={() => setUseFeatureAgain("yes")}
                >
                  <IonLabel
                    color={useFeatureAgain == "yes" ? "primary" : ""}
                    className={useFeatureAgain == "yes" ? "bold" : ""}
                  >
                    Yes
                  </IonLabel>
                </IonItem>
                <IonItem
                  lines="none"
                  className="ion-text-center radio-no-background itemBackgroundFix"
                  onClick={() => setUseFeatureAgain("no")}
                >
                  <IonLabel
                    color={useFeatureAgain == "no" ? "primary" : ""}
                    className={useFeatureAgain == "no" ? "bold" : ""}
                  >
                    No
                  </IonLabel>
                </IonItem>
              </IonRadioGroup>
              <IonLabel className="ion-text-center">
                <h2 className="bolding">
                  Would you recommend this feature to a friend?
                </h2>
              </IonLabel>
              <IonRadioGroup
                value={recommendFeature}
                onIonChange={(e) => setRecommendFeature(e.detail.value)}
              >
                <IonItem
                  lines="none"
                  className="ion-text-center itemBackgroundFix"
                  onClick={() => setRecommendFeature("yes")}
                >
                  <IonLabel
                    color={recommendFeature == "yes" ? "primary" : ""}
                    className={recommendFeature == "yes" ? "bold" : ""}
                  >
                    Yes
                  </IonLabel>
                </IonItem>
                <IonItem
                  lines="none"
                  className="ion-text-center radio-no-background itemBackgroundFix"
                  onClick={() => setRecommendFeature("no")}
                >
                  <IonLabel
                    color={recommendFeature == "no" ? "primary" : ""}
                    className={recommendFeature == "no" ? "bold" : ""}
                  >
                    No
                  </IonLabel>
                </IonItem>
              </IonRadioGroup>
              {recommendFeature == "no" && (
                <>
                  <IonLabel className="ion-text-center">
                    <h2 className="bolding">Why Not?</h2>
                  </IonLabel>
                  <IonTextarea
                    className="textarea ion-margin-bottom"
                    autoGrow={true}
                    onIonChange={(e) => setWhyNotRecommend(e.detail.value)}
                  ></IonTextarea>
                </>
              )}
              <IonLabel className="ion-text-center">
                <h2 className="bolding">
                  Comments / feedback / suggestion on how we can improve this
                  service?
                </h2>
              </IonLabel>
              <IonTextarea
                className="textarea"
                autoGrow={true}
                onIonChange={(e) => setFeedback(e.detail.value)}
              ></IonTextarea>
            </>
          )}
          <IonButton
            expand="block"
            shape="round"
            className="ion-margin submitFeedbackButton"
            disabled={answered == ""}
            onClick={submitResponse}
          >
            Submit
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default Popup;
