import { Redirect, Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import ProfileCreate from "./ProfileCreate";
// import NotAllowed from "./NotAllowed";

import Home from "./Home";

import { UserContext } from "../../../lib/context";
import { useContext, useEffect, useState } from "react";
import NotAllowed from "../NotAllowed";
//import Telemedicine from "../../components/Tools/TeleMedicine";

const FaceScanOnlyRouter = () => {
  //user user context
  const { user, profile } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {profile.role === "member" ? (
        <>
          {" "}
          <IonReactRouter>
            {profile && profile.accountSetupComplete ? (
              <>
                <IonRouterOutlet>
                  <Route path="/home" component={Home} />
                  {/* <Route path="/update-profile" component={UpdateProfileData} />
                <Route path="/change-email" component={ChangeEmail} />
                <Route path="/scan-list" component={ScanList} />
                <Route path="/diagnostics" component={DeepAffex} />
                <Route path="/guest-scan" component={GuestScan} />


                <Route path="/scan/:id" component={Scan} /> */}
                  {/* <Route
                  path="/healthy-eating"
                  component={ExploreHealthyEating}
                />
                <Route
                  path="/fitness-and-activity"
                  component={ExploreFitnessAndActivity}
                />
                <Route path="/living-well" component={LivingWell} />
                <Route
                  path="/practicing-mindfulness"
                  component={PracticingMindfulness}
                />
                <Route
                  path="/healing-and-preventing-injuries"
                  component={HealingAndPreventingInjuries}
                />
                <Route
                  path="/overcoming-disabilities"
                  component={OvercomingDisabalities}
                />
                <Route
                  path="/recover-from-addiction"
                  component={RecoverFromAddiction}
                />
                <Route path="/about-us" component={AboutUs} />
                <Route
                  path="/manage-stress-and-anxiety"
                  component={ManageStressAndAnxiety}
                />
                <Route path="/session/:id" component={Session} />
                <Route path="/my-coach" component={MyCoach} /> */}
                  {/* <Route path="/update-address" component={UpdateAddress} />
                <Route path="/change-phone" component={ChangePhone} /> */}

                  <Redirect to="/home" />
                </IonRouterOutlet>
              </>
            ) : (
              <>{loading ? null : <ProfileCreate />}</>
            )}
          </IonReactRouter>
        </>
      ) : (
        <NotAllowed />
      )}
    </>
  );
};

export default FaceScanOnlyRouter;
