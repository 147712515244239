import {
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
//import spinner
import React, { useEffect, useState } from "react";
import "./HRAStyle.css";

type Props = {
  slides;
  buttonHandler;
  profileHandler;
  profile;
  slideIndex;
};

const AddStress: React.FC<Props> = ({
  slides,
  buttonHandler,
  profileHandler,
  profile,
  slideIndex,
}) => {
  const [answer, setAnswer] = useState(profile.stressLevelSatisfaction);

  const handleColor = (item) => {
    const veryHappy = document.getElementById("StressVeryHappy");
    const happy = document.getElementById("StressHappy");
    const neutral = document.getElementById("StressNeutral");
    const unhappy = document.getElementById("StressUnhappy");
    const veryUnhappy = document.getElementById("StressVeryUnhappy");

    veryHappy.classList.remove("pickedItem");
    happy.classList.remove("pickedItem");
    neutral.classList.remove("pickedItem");
    unhappy.classList.remove("pickedItem");
    veryUnhappy.classList.remove("pickedItem");

    if (item === "Very Happy") {
      item = "veryHappy";
    }

    if (item === "Happy") {
      item = "happy";
    }

    if (item === "Neutral") {
      item = "neutral";
    }

    if (item === "Unhappy") {
      item = "unhappy";
    }

    if (item === "Very Unhappy") {
      item = "veryUnhappy";
    }

    if (item === "veryHappy") {
      veryHappy.classList.add("pickedItem");
    }

    if (item === "happy") {
      happy.classList.add("pickedItem");
    }

    if (item === "neutral") {
      neutral.classList.add("pickedItem");
    }

    if (item === "unhappy") {
      unhappy.classList.add("pickedItem");
    }

    if (item === "veryUnhappy") {
      veryUnhappy.classList.add("pickedItem");
    }
  };

  const validateValues = () => {
    if (
      answer === "" ||
      answer === undefined ||
      answer === null ||
      answer === " "
    ) {
      buttonHandler(true);
    } else {
      buttonHandler(false);
    }
  };

  useEffect(() => {
    if (slideIndex === 10) {
      validateValues();
      handleColor(answer);
    }
  }, [answer, slideIndex]);

  return (
    <>
      <IonCardTitle class="ion-text-start wellnesSlideTitle">
        How happy do you feel about your stress level?
      </IonCardTitle>
      <div className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem
                className="mainGoalItem "
                id="StressVeryHappy"
                lines="none"
                fill="outline"
                onClick={(e) => {
                  setAnswer("Very Happy");
                  handleColor("veryHappy");
                  profile.stressLevelSatisfaction = "Very Happy";
                  profileHandler(profile);
                }}
              >
                <IonLabel className="ion-text-center"> Very Happy </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem
                className="mainGoalItem "
                id="StressHappy"
                lines="none"
                fill="outline"
                onClick={(e) => {
                  setAnswer("Happy");
                  handleColor("happy");
                  profile.stressLevelSatisfaction = "Happy";
                  profileHandler(profile);
                }}
              >
                <IonLabel className="ion-text-center"> Happy </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem
                className="mainGoalItem "
                id="StressNeutral"
                lines="none"
                fill="outline"
                onClick={(e) => {
                  setAnswer("Neutral");
                  handleColor("neutral");
                  profile.stressLevelSatisfaction = "Neutral";
                  profileHandler(profile);
                }}
              >
                <IonLabel className="ion-text-center"> Neutral </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem
                className="mainGoalItem "
                id="StressUnhappy"
                lines="none"
                fill="outline"
                onClick={(e) => {
                  setAnswer("Unhappy");
                  handleColor("unhappy");
                  profile.stressLevelSatisfaction = "Unhappy";
                  profileHandler(profile);
                }}
              >
                <IonLabel className="ion-text-center"> Unhappy </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem
                className="mainGoalItem "
                id="StressVeryUnhappy"
                lines="none"
                fill="outline"
                onClick={(e) => {
                  setAnswer("Very Unhappy");
                  handleColor("veryUnhappy");
                  profile.stressLevelSatisfaction = "Very Unhappy";
                  profileHandler(profile);
                }}
              >
                <IonLabel className="ion-text-center"> Very Unhappy </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default AddStress;
