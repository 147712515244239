import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import React, { useContext } from "react";
import "firebase/compat/auth";
import { UserContext } from "../../../lib/context";
import SettingsHeaderCard from "./SettingsHeaderCard";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import {
  chevronForward,
  lockClosedOutline,
  mailOutline,
  callOutline,
  heartOutline,
  personOutline,
  homeOutline,
  watchOutline,
  cardOutline,
} from "ionicons/icons";
import TimeZone from "../Preferences/TimeZone";
import { SettingsList } from "../../../Interfaces/Settings/Settings";

const Settings: React.FC = () => {
  const { user, profile } = useContext(UserContext);

  const [emailNotificationsOn, setEmailNotificationsOn] = React.useState(profile.emailNotificationsOn === false ? false : true);


  const updateSMSNotifications = async () => {
    const db = getFirestore();
    const userDoc = doc(db, "users", user.uid);
    await updateDoc(userDoc, {
      smsNotificationsOn: !profile.smsNotificationsOn,
    });
  };

  const updateEmailNotifications = async () => {
    const db = getFirestore();
    const userDoc = doc(db, "users", user.uid);
    await updateDoc(userDoc, {
      emailNotificationsOn: !emailNotificationsOn,
    }).then(() => {
      // console.log("emailNotificationsOn updated");
      // update koroPublicAvatars collection
      const koroPublicAvatarsDoc = doc(db, "koroPublicAvatars", user.uid);
      updateDoc(koroPublicAvatarsDoc, {
        emailNotificationsOn: !emailNotificationsOn,
      }).then(() => {
        // console.log("koroPublicAvatars emailNotificationsOn updated");
      });

    });
    setEmailNotificationsOn(!emailNotificationsOn);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="tabs/home" />
          </IonButtons>
          <IonTitle className="mainPageTitle">Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <SettingsHeaderCard />
        <IonItem className="new-section ion-margin-bottom" lines="none">
          <p className="new-section-title">Information</p>
        </IonItem>
        <IonList>
          {SettingsList.map((item) => {
            return (
              <IonItem lines="none" routerLink={item.url} detail={false}>
                <IonIcon icon={chevronForward} slot="end"></IonIcon>
                <IonIcon icon={item.mdIcon} slot="start"></IonIcon>

                <p className="settingsHeaderCardInfo">{item.title}</p>
              </IonItem>
            );
          })}
          {profile.accountType === "individual" ? <IonItem lines="none" routerLink={"manage-subscription"} detail={false} >
            <IonIcon icon={chevronForward} slot="end"></IonIcon>
            <IonIcon icon={cardOutline} slot="start"></IonIcon>
            <p className="settingsHeaderCardInfo" >Manage Subscription</p>
          </IonItem> : null}
          
          <IonItem lines="none" routerLink={"health-device"} detail={false} disabled={true}>
            <IonIcon icon={chevronForward} slot="end"></IonIcon>
            <IonIcon icon={watchOutline} slot="start"></IonIcon>
            <p className="settingsHeaderCardInfo" >Health Device (coming soon)</p>
          </IonItem>
        </IonList>
        <IonItem className="new-section ion-margin-bottom" lines="none">
          <p className="new-section-title">Preference</p>
        </IonItem>
        <IonList>
        <IonItem>
            <IonLabel>Email Notifications</IonLabel>
            <IonToggle
              slot="end"
              onClick={updateEmailNotifications}
              checked={emailNotificationsOn}
            ></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>SMS Notifications</IonLabel>
            <IonToggle
              slot="end"
              onClick={updateSMSNotifications}
              checked={profile.smsNotificationsOn}
            ></IonToggle>
          </IonItem>
          <TimeZone />
          {/* <IonItem>
            <IonLabel>Checked Toggle</IonLabel>
            <IonToggle slot="end" checked={true}></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Disabled Toggle</IonLabel>
            <IonToggle slot="end" disabled={true}></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Disabled Checked Toggle</IonLabel>
            <IonToggle slot="end" checked={true} disabled={true}></IonToggle>
          </IonItem> */}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
