import React from "react";
import "../Popup.css";

const Popup_waistToHeight = () => {
  return (
    <div className="ion-padding popup-wrapper">
      <p>
        Waist-to-height ratio is an individual's waist circumference expressed
        as a percentage of their height (with both measured in the same units).
        For the average person, the scan will estimate this to within 4
        percentage points of its actual value.
      </p>
      <p>
        Waist-to-height ratio is a particularly good predictor of cardiovascular
        disease risk. It is associated with cardiovascular disease risk factors
        like elevated total cholesterol and lower high-density lipoprotein
        (HDL).
      </p>
      <p>
        On average, the scan will estimate your waist-to-height ratio to within
        4 percentage points of its actual value.
      </p>
      <p>
        For more information, see: Zhou, W., Wang, Y., Gu, X., Feng, Z. P., Lee,
        K., Peng, Y., &amp; Barszczyk, A. (2020) Importance of general
        adiposity, visceral adiposity and vital signs in predicting blood
        biomarkers using machine learning.{" "}
        <em>International Journal of Clinical Practice</em>, e13664.
      </p>
    </div>
  );
};

export default Popup_waistToHeight;
