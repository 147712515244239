import { IonIcon } from "@ionic/react";
import { shareOutline } from "ionicons/icons";
import "./style.css";

import { Share } from '@capacitor/share';

type Props = {
  url: string;
  text: string;
  title: string;
  dialogTitle: string;
}





const Template: React.FC<Props> = ({url, text, title, dialogTitle}) => {

  const share = async () =>{
    await Share.share({
      title: title,
      text: text,
      url: url,
      dialogTitle: dialogTitle
    });
  }

  return (
    <>  <IonIcon
    onClick={() => share()}
    icon={shareOutline}
    slot="end"
    size="medium"
    className="toolBarIcon"
    color="primary"
  ></IonIcon></>
  );
};

export default Template;