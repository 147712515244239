import {
  IonButton,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { checkboxOutline, squareOutline } from "ionicons/icons";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../lib/context";
import "./HRAStyle.css";

type Props = {
  slides;
  buttonHandler;
  profileHandler;
  profile;
  slideIndex;
};

const AddMainHealthGoal: React.FC<Props> = ({
  slides,
  buttonHandler,
  profileHandler,
  profile,
  slideIndex,
}) => {
  const [loseWeight, setLoseWeight] = useState(profile.loseWeight);
  const [eatBetter, setEatBetter] = useState(profile.eatBetter);
  const [increaseActivity, setIncreaseActivity] = useState(
    profile.increaseActivity
  );
  const [improveMentalHealth, setImproveMentalHealth] = useState(
    profile.improveMentalHealth
  );
  const [manageStress, setManageStress] = useState(profile.manageStress);
  const [betterSleep, setBetterSleep] = useState(profile.getBetterSleep);
  const [quitSmoking, setQuitSmoking] = useState(profile.quitSmoking);
  const [checked, setChecked] = useState(false);

  // const [mainGoal, setMainGoal] = useState('');
  // const [validHealthGoal, setValidHealthGoal] = useState(false)
  // const [looseWeight, setLooseWeight] = useState("success");

  // get slide ref

  const RenderLoseWeight = () => {
    if (loseWeight) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setLoseWeight(!loseWeight);
            profile.loseWeight = !loseWeight;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Lose Weight </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setLoseWeight(!loseWeight);
            profile.loseWeight = !loseWeight;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Lose Weight </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderEatBetter = () => {
    if (eatBetter) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setEatBetter(!eatBetter);
            profile.eatBetter = !eatBetter;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Eat better </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setEatBetter(!eatBetter);
            profile.eatBetter = !eatBetter;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Eat better </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderIncreaseActivity = () => {
    if (increaseActivity) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setIncreaseActivity(!increaseActivity);
            profile.increaseActivity = !increaseActivity;
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Increase my activity </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setIncreaseActivity(!increaseActivity);
            profile.increaseActivity = !increaseActivity;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Increase my activity </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderMentalHealth = () => {
    if (improveMentalHealth) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setImproveMentalHealth(!improveMentalHealth);
            profile.improveMentalHealth = !improveMentalHealth;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Improve mental health </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setImproveMentalHealth(!improveMentalHealth);
            profile.improveMentalHealth = !improveMentalHealth;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Improve mental health </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderManageStress = () => {
    if (manageStress) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setManageStress(!manageStress);
            profile.manageStress = !manageStress;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Manage stress </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setManageStress(!manageStress);
            profile.manageStress = !manageStress;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Manage stress </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderBetterSleep = () => {
    if (betterSleep) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setBetterSleep(!betterSleep);
            profile.getBetterSleep = !betterSleep;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Get better sleep </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setBetterSleep(!betterSleep);
            profile.getBetterSleep = !betterSleep;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Get better sleep </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderQuitSmoking = () => {
    if (quitSmoking) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setQuitSmoking(!quitSmoking);
            profile.quitSmoking = !quitSmoking;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Quit smoking </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setQuitSmoking(!quitSmoking);
            profile.quitSmoking = !quitSmoking;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Quit smoking </IonLabel>
        </IonItem>
      );
    }
  };

  const validateValues = () => {
    if (
      loseWeight ||
      eatBetter ||
      increaseActivity ||
      improveMentalHealth ||
      manageStress ||
      betterSleep ||
      quitSmoking
    ) {
      buttonHandler(false);
    } else {
      buttonHandler(true);
    }
  };

  useEffect(() => {
    if (slideIndex === 5) {
      validateValues();
    }
  }, [
    loseWeight,
    eatBetter,
    increaseActivity,
    improveMentalHealth,
    manageStress,
    betterSleep,
    quitSmoking,
    slideIndex,
  ]);

  return (
    <>
      <IonCardTitle class="ion-text-start slideTitle">
        Select your main health goals
      </IonCardTitle>
      <div className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <RenderLoseWeight />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderEatBetter />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderIncreaseActivity />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderMentalHealth />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderManageStress />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderBetterSleep />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderQuitSmoking />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default AddMainHealthGoal;
