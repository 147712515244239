import React from "react";
import "../Popup.css";

const Popup_absi = () => {
  return (
    <div className="ion-padding popup-wrapper">
      <p>
        Body Shape Index is a measure of abdominal region size (as determined by
        waist circumference) relative to overall body size (as determined by
        Body Mass Index and height). A low Body Shape Index corresponds to a
        ‘chilli pepper'-shaped body. As BSI increases, body shape resembles a
        pear, then a pineapple, and then an apple. A larger abdominal region
        suggests elevated levels of deep abdominal fat, which is associated with
        various health risks.
      </p>
      <p>
        Body Shape Index is a particularly good predictor of mortality (from all
        causes), as well as a fair predictor of diabetes and cardiovascular
        diseases. It is associated with elevated triglyceride levels, which may
        partially contribute to the development of cardiovascular diseases.
      </p>
      <p>
        For more information, see: Zhou, W., Wang, Y., Gu, X., Feng, Z. P., Lee,
        K., Peng, Y., &amp; Barszczyk, A. (2020) Importance of general
        adiposity, visceral adiposity and vital signs in predicting blood
        biomarkers using machine learning.{" "}
        <em>International Journal of Clinical Practice</em>, e13664.
      </p>
    </div>
  );
};

export default Popup_absi;
