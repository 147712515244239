import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
//import spinner
import React, { useContext } from "react";
import { UserContext } from "../../../lib/context";

import "./TimeZone.css";

const SetTimeZone: React.FC = () => {
  const { user, profile } = useContext(UserContext);
  const getCurrentTimeZoneTime = (timeZoneCode) => {
    //get time from time zone code and return it
    var myDate = new Date();
    var pstDate = myDate.toLocaleString("en-US", {
      timeZone: timeZoneCode,
    });
    //trime the date to just the time
    pstDate = pstDate.substring(pstDate.indexOf(",") + 2);
    //trim the seconds
    pstDate = pstDate.substring(0, pstDate.lastIndexOf(":"));
    //ADD ON AM OR PM
    pstDate =
      pstDate +
      myDate
        .toLocaleString("en-US", {
          timeZone: timeZoneCode,
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        })
        .substring(
          myDate
            .toLocaleString("en-US", {
              timeZone: timeZoneCode,
              hour12: true,
              hour: "numeric",
              minute: "numeric",
            })
            .indexOf(" ")
        );

    // console.log(pstDate);

    return pstDate;
  };

  const timeZones = [
    {
      value: "Pacific/Honolulu",
      label: "Hawaii Standard Time",
      time: getCurrentTimeZoneTime("Pacific/Honolulu"),
    },
    {
      value: "America/Anchorage",
      label: "Alaska Standard Time",
      time: getCurrentTimeZoneTime("America/Anchorage"),
    },
    {
      value: "America/Los_Angeles",
      label: "Pacific Standard Time",
      time: getCurrentTimeZoneTime("America/Los_Angeles"),
    },
    {
      value: "America/Denver",
      label: "Mountain Standard Time",
      time: getCurrentTimeZoneTime("America/Denver"),
    },
    {
      value: "America/Chicago",
      label: "Central Standard Time",
      time: getCurrentTimeZoneTime("America/Chicago"),
    },
    {
      value: "America/New_York",
      label: "Eastern Standard Time",
      time: getCurrentTimeZoneTime("America/New_York"),
    },
  ];

  const setNewTimeZone = (e) => {
    // console.log(e.detail.value);
    //update user doc in firebase
    updateDoc(doc(getFirestore(), "users", user.uid), {
      timeZone: e.detail.value,
    });
  };

  return (
    <IonItem>
      <IonLabel className="ion-text-wrap" position="stacked">Time Zone</IonLabel>

      <IonSelect
        className="timeZoneSelector ion-text-wrap"
        onIonChange={(e) => setNewTimeZone(e)}
        value={profile.timeZone}
      >
        {timeZones.map((timeZone) => (
          <IonSelectOption value={timeZone.value} className="ion-text-wrap">
            {timeZone.label} - {timeZone.time}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

export default SetTimeZone;
