import { personOutline, home } from "ionicons/icons";

import ChangeEmail from "../../components/Account/Settings/Profile/ChangeEmail"
import MyCoach from "../../components/Appointment/Schedule/MyCoach"
import Dietitian from "../../components/Appointment/Schedule/Dietitian";
import StrengthAndConditioning from "../../components/Appointment/Schedule/StrengthAndConditioning";
import PhysicalTherapist from "../../components/Appointment/Schedule/PhysicalTherapist";
import QuickCheckin from "../../components/Appointment/Schedule/QuickCheckin";

import Nutrition from "../../assets/icons/nutrition.svg";
import Dumbbell from "../../assets/icons/dumbbell.svg";
import KneePain from "../../assets/icons/knee-pain.svg";
import PeopleDialogue from "../../assets/icons/people-dialogue.svg";
import Doctor from "../../assets/icons/doctor.svg";
import Psychiatry from "../../assets/icons/mental-disorder.svg";
import QuestionMark from "../../assets/icons/question-mark-outline.svg";

export interface VisitType {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  component?: any;
  isWalmartHealth?: boolean;
}

export const VisitTypesList: VisitType[] = [
  {
    title: "My Health Coach",
    url: "/tabs/appointment/my-health-coach",
    iosIcon: personOutline,
    mdIcon: personOutline,
    component: MyCoach,
    isWalmartHealth: false,
  },
  {
    title: "Dietitian",
    url: "/tabs/appointment/dietitian",
    iosIcon: Nutrition,
    mdIcon: Nutrition,
    component: Dietitian,
    isWalmartHealth: false,
  },
  {
    title: "Strength & Conditioning Coach",
    url: "/tabs/appointment/strength-and-conditioning",
    iosIcon: Dumbbell,
    mdIcon: Dumbbell,
    component: StrengthAndConditioning,
    isWalmartHealth: false,
  },
  { title: "Physical Therapist",
    url: "/tabs/appointment/physical-therapist",
    iosIcon: KneePain,
    mdIcon: KneePain,
    component: PhysicalTherapist,
    isWalmartHealth: false,
  },
  {
    title: "Counseling",
    url: "/tabs/appointment/counseling",
    iosIcon: PeopleDialogue,
    mdIcon: PeopleDialogue,
    isWalmartHealth: true,
    component: MyCoach,
  },
  {
    title: "Urgent Care",
    url: "/tabs/appointment/urgent-care",
    iosIcon: Doctor,
    mdIcon: Doctor,
    isWalmartHealth: true,
    component: MyCoach, 
  },
  {
    title: "Psychiatry",
    url: "/tabs/appointment/psychiatry",
    iosIcon: Psychiatry,
    mdIcon: Psychiatry,
    isWalmartHealth: true,
    component: MyCoach,
  }
];