import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import { useContext, useEffect, useState } from "react";
import React from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import "firebase/compat/auth";
import { UserContext } from "../../lib/context";
import "./MyCoach.css";

const MyCoach: React.FC = () => {
  const [coach, setCoach] = useState<any>({});
  const { user, profile } = useContext(UserContext);

  //get coach document from firestore
  const getCoach = async () => {
    const docRef = doc(getFirestore(), "users", profile.coach);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setCoach(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
    }
  };

  useEffect(() => {
    getCoach();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="tabs/home" />
          </IonButtons>

          <IonTitle className="mainPageTitle">My Coach</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className=" ion-text-center" >
        <IonCard>
          <IonCardHeader className="ion-text-center">
            <div className="wrapper-my-coach">
              <img src={coach.photoURL} alt="" />
            </div>
          </IonCardHeader>
          <IonCardContent>
            <h1> {coach.firstName + " " + coach.lastName} </h1>
            <IonLabel>
              {coach.title}
              <br></br>
            </IonLabel>
            <IonLabel className="qualifications">
              {coach.qualifications}
              <br></br>
              <br></br>
            </IonLabel>
            <IonText className="ion-text-center">
              {" "}
              {coach.about} <br></br> <br></br>
            </IonText>
            {/* <IonButton className="ion-text-center" expand="full" shape="round" routerLink="/tabs/appointment/my-health-coach">
              Schedule a call
            </IonButton> */}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default MyCoach;
