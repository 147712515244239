import firebaseApp from "../lib/Firebase";
import getStripe from "./initializeStripe";
import firestore from "../lib/Firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { ref } from "firebase/storage";
import { Profile } from "../Interfaces/Profile";
import { Browser } from "@capacitor/browser";

export const createCheckoutSession = async (uid: string, priceId: string) => {

    const openBrowser = async (url) => {
        await Browser.open({ url: url });
      };
    
  // const firestore = firebaseApp.firestore();

  // const ref = doc(getFirestore(), "users", uid, "checkout_sessions", "price_1J4k6tLjvXJYb3Y2k4l7j9fK");
  const collectionRef = collection(
    getFirestore(),
    "users",
    uid,
    "checkout_sessions"
  );
  const docData = {
    price: priceId,
    success_url: "https://alliedwellness.com/thankyou/",
    cancel_url: "https://alliedwellness.com/thankyou/",
  };

  addDoc(collectionRef, docData).then((docRef) => {
    // console.log("Document written with ID: ", docRef.id);
    const unsubscribe = onSnapshot(docRef, async (docSnap) => {
      // const data: Profile = doc.data() as Profile;
      // console.log("got doc", docSnap.data());
      const { sessionId, url } = docSnap.data();
      if (sessionId) {
        const stripe = await getStripe();
        // console.log("stripe", stripe);
        // stripe.redirectToCheckout({ sessionId });
        openBrowser(url)
      }
    });

  });
};