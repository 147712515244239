import { IonButton, IonCardTitle, IonIcon, IonLabel } from "@ionic/react";
//import spinner
import React, { useEffect } from "react";
import "./HRAStyle.css";
import { checkmarkCircle } from "ionicons/icons";

type Props = {
  slides;
  buttonHandler;
  profileHandler;
  profile;
  slideIndex;
};

const HRACompleteSCreen: React.FC<Props> = ({
  slides,
  buttonHandler,
  profileHandler,
  profile,
  slideIndex,
}) => {
  useEffect(() => {
    if (slideIndex === 2) {
      buttonHandler(false);
    }
  }, [slideIndex]);

  return (
    <>
      <div className="ionPaddingTopMedium">
        <div className="center-container-two">
          <div className="ion-padding-start ion-padding-end">
            <div className="centerOfScreen">
              {profile.accountType == "individual" ? (
                <>
                  <IonIcon
                    icon={checkmarkCircle}
                    color="success"
                    className="successCheckBox"
                  ></IonIcon>
                  <IonLabel>
                    <h1 className="ion-margin ion-padding ion-text-center">
                      Start your 2-week free trial with a health Coach
                    </h1>
                    <h2 className="ion-text-center">
                      Your first two weeks are on us! 
                    </h2>
                  </IonLabel>
                  <IonLabel><p className="ion-text-center ion-margin-top ion-padding-top">We don't need your credit card now - at the end of your free trial you can choose a payment plan that works best for you.</p></IonLabel>
                </>
              ) : (
                <>
                  {" "}
                  <IonIcon
                    icon={checkmarkCircle}
                    color="success"
                    className="successCheckBox"
                  ></IonIcon>
                  <IonLabel>
                    <h1 className="ion-margin ion-padding ion-text-center">
                      Account created!
                    </h1>
                    <p className="ion-text-center">
                      You account is now active and ready to use.
                    </p>
                  </IonLabel>
                </>
              )}
            </div>
            {/* <IonButton
            expand="block"
            className="ion-margin-top "
            type="submit"
            onClick={() => {
              profileHandler({});
              nextHandler();
            }}
          >
            Continue
          </IonButton> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HRACompleteSCreen;
