import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonLabel,
  IonRouterLink,
  IonRow,
} from "@ionic/react";
import "./index.css";
import { GoalsList } from "../../../Interfaces/Goals";
import InterestTile from "../ItemTile";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { useContext } from "react";
import { UserContext } from "../../../../../lib/context";

type Props = {
  refresh;
  focus;
};

const GoalSlider: React.FC<Props> = ({ refresh, focus }) => {
  const { koroPublicAvatar } = useContext(UserContext);

  if (focus == "all") {
    return (
      <div className="on-padding-start ion-margin">
        <Swiper
          spaceBetween={10}
          slidesPerView={2.5}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {GoalsList.map((goal, index) => {
            return (
              <SwiperSlide key={goal.title} >
                <InterestTile item={goal} refresh={refresh} />
                {/* {goal.title} */}
                {/* <img src = {goal.img} /> */}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  }

  // return tiles that only match with koroPublicAvatar.koroInterest
  if (focus == "focus") {
    return (
      <div className="on-padding-start ion-margin">
        <Swiper spaceBetween={10}
          slidesPerView={2.5}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
          >
          {GoalsList.map((goal, index) => {
            if (koroPublicAvatar.koroGoals.includes(goal.id)) {
              return (
                <SwiperSlide key={goal.title}>
                  <InterestTile item={goal} refresh={refresh} />
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </div>
    );
  }

  return <></>;
};

export default GoalSlider;
