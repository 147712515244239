import {
  IonModal,
  IonContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonThumbnail,
  IonIcon,
  IonInput,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { addDoc, collection, doc, getFirestore, updateDoc } from "firebase/firestore";
import { pencil } from "ionicons/icons";
import React from "react";
import { useRef } from "react";

import BloodDrop from "../../assets/icons/blood-drop.svg";
import Heart from "../../assets/icons/heart.svg";
import Person from "../../assets/icons/person.svg";
import FootSteps from "../../assets/icons/footsteps.svg";
import Waist from "../../assets/icons/waist.svg";
import Scale from "../../assets/icons/scale-svgrepo-com.svg";
import Calculator from "../../assets/icons/calculator.svg";

import "./LogMetrics.css";

export default function LogMetrics(props) {
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [a1c, setA1c] = React.useState(null);
  const [systolic, setSystolic] = React.useState(null);
  const [diastolic, setDiastolic] = React.useState(null);
  const [bodyFat, setBodyFat] = React.useState(null);
  const [steps, setSteps] = React.useState(null);
  const [waistCircumference, setWaistCircumference] = React.useState(null);
  const [weight, setWeight] = React.useState(null);
  const [bmi, setBmi] = React.useState(null);

  function confirm() {
    modal.current?.dismiss(input.current?.value, "confirm");
  }

  async function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    setA1c(null);
    setSystolic(null);
    setDiastolic(null);
    setBodyFat(null);
    setSteps(null);
    setWaistCircumference(null);
    setWeight(null);
    setBmi(null);
  }

  const logA1C = async () => {
    const dateArray = props.date.split("-");
    const timestamp = new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2])
    );

    //check if a1c is valid and is a number
    if (a1c && !isNaN(parseFloat(a1c))) {
      await addDoc(collection(getFirestore(), "metrics"), {
        type: "A1C",
        value: a1c,
        date: props.date,
        timestamp: timestamp,
        uid: props.user.uid,
        email: props.user.email,
      }).then(() => {
        updateTracking();
      });
      setA1c(null);
    } else {
      alert("Please enter a valid A1C");
    }
  };

  const logBP = async () => {
    const dateArray = props.date.split("-");
    const timestamp = new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2])
    );

    //check if a1c is valid and is a number
    if (
      systolic &&
      diastolic &&
      !isNaN(parseFloat(systolic)) &&
      !isNaN(parseFloat(diastolic))
    ) {
      await addDoc(collection(getFirestore(), "metrics"), {
        type: "blood-pressure",
        systolic: systolic,
        diastolic: diastolic,
        date: props.date,
        timestamp: timestamp,
        uid: props.user.uid,
        email: props.user.email,
      }).then(() => {
        updateTracking();
      });
      setDiastolic(null);
      setSystolic(null);
    } else {
      alert("Please enter a valid Blood Pressure");
    }
  };

  const logBodyFat = async () => {
    const dateArray = props.date.split("-");
    const timestamp = new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2])
    );

    //check if a1c is valid and is a number
    if (bodyFat && !isNaN(parseFloat(bodyFat))) {
      await addDoc(collection(getFirestore(), "metrics"), {
        type: "body-fat",
        value: bodyFat,
        date: props.date,
        timestamp: timestamp,
        uid: props.user.uid,
        email: props.user.email,
      }).then(() => {
        updateTracking();
      });
      setBodyFat(null);
    } else {
      alert("Please enter a valid Body Fat");
    }
  };

  const logSteps = async () => {
    const dateArray = props.date.split("-");
    const timestamp = new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2])
    );

    //check if a1c is valid and is a number
    if (steps && !isNaN(parseFloat(steps))) {
      await addDoc(collection(getFirestore(), "metrics"), {
        type: "steps",
        value: steps,
        date: props.date,
        timestamp: timestamp,
        uid: props.user.uid,
        email: props.user.email,
      }).then(() => {
        updateTracking();
      });
      setSteps(null);
    } else {
      alert("Please enter a valid steps");
    }
  };

  const logWaistCircumference = async () => {
    const dateArray = props.date.split("-");
    const timestamp = new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2])
    );

    //check if a1c is valid and is a number
    if (waistCircumference && !isNaN(parseFloat(waistCircumference))) {
      await addDoc(collection(getFirestore(), "metrics"), {
        type: "waist-circumference",
        value: waistCircumference,
        date: props.date,
        timestamp: timestamp,
        uid: props.user.uid,
        email: props.user.email,
      }).then(() => {
        updateTracking();
      });
      setWaistCircumference(null);
    } else {
      alert("Please enter a valid waist circumference");
    }
  };

  const logWeight = async () => {
    const dateArray = props.date.split("-");
    const timestamp = new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2])
    );

    //check if a1c is valid and is a number
    if (weight && !isNaN(parseFloat(weight))) {
      await addDoc(collection(getFirestore(), "metrics"), {
        type: "weight",
        value: weight,
        date: props.date,
        timestamp: timestamp,
        uid: props.user.uid,
        email: props.user.email,
      }).then(async () => {
        const ref = doc(getFirestore(), "users", props.user.uid);
        await updateDoc(ref, {
          weight: weight,
        }).then(() => {
          updateTracking();
        });
      });;
      setWeight(null);
    } else {
      alert("Please enter a valid weight");
    }
  };

  const logBMI = async () => {
    const dateArray = props.date.split("-");
    const timestamp = new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2])
    );

    //check if a1c is valid and is a number
    if (bmi && !isNaN(parseFloat(bmi))) {
      await addDoc(collection(getFirestore(), "metrics"), {
        type: "bmi",
        value: bmi,
        date: props.date,
        timestamp: timestamp,
        uid: props.user.uid,
        email: props.user.email,
      }).then(() => {
        updateTracking();
      });

      setBmi(null);
    } else {
      alert("Please enter a valid BMI");
    }
  };

  const updateTracking = async () => {
  
      updateDoc(doc(getFirestore(), "tracking", props.user.uid), {
        lastMetricLog: new Date(),
        email: props.user.email,
        uid: props.user.uid
      });
   
  };

  return (
    <>
      <IonModal
        ref={modal}
        trigger="open-log-metrics"
        onWillDismiss={(ev) => onWillDismiss(ev)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle className="mainPageTitle">Log Metrics </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>
            <IonIcon
              icon={BloodDrop}
              color="primary"
              size="large"
              className="ion-padding"
            ></IonIcon>

            <IonLabel>A1C</IonLabel>
            <IonInput
              className="metric-input ion-text-center"
              type="number"
              value={a1c}
              placeholder="Enter A1C"
              onIonChange={(e) => setA1c(Number(e.detail.value))}
            />
            <IonButton
              size="default"
              slot="end"
              disabled={a1c == null || a1c === 0}
              onClick={logA1C}
            >
              Log
            </IonButton>
          </IonItem>
          <IonItem>
            <IonIcon
              icon={Heart}
              color="primary"
              size="large"
              className="ion-padding"
            ></IonIcon>

            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>
                    Blood Pressure <p className="metric-unit">mm HG</p>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonInput
                    className="metric-input ion-text-center metric-input-bp"
                    type="number"
                    value={systolic}
                    placeholder="Systolic"
                    onIonChange={(e) => setSystolic(Number(e.detail.value))}
                  />
                </IonCol>
                <IonCol>
                  <IonInput
                    className="metric-input ion-text-center metric-input-bp"
                    type="number"
                    value={diastolic}
                    placeholder="Diastolic"
                    onIonChange={(e) => setDiastolic(Number(e.detail.value))}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonButton
              size="default"
              slot="end"
              disabled={
                systolic == null ||
                systolic === 0 ||
                diastolic == null ||
                diastolic === 0
              }
              onClick={logBP}
            >
              Log
            </IonButton>
          </IonItem>
          <IonItem>
            <IonIcon
              icon={Person}
              color="primary"
              size="large"
              className="ion-padding"
            ></IonIcon>

            <IonLabel>Body Fat %</IonLabel>
            <IonInput
              className="metric-input ion-text-center"
              type="number"
              value={bodyFat}
              placeholder=""
              onIonChange={(e) => setBodyFat(Number(e.detail.value))}
            />
            <IonButton
              size="default"
              slot="end"
              disabled={bodyFat == null || bodyFat === 0}
              onClick={logBodyFat}
            >
              Log
            </IonButton>
          </IonItem>
          <IonItem>
            <IonIcon
              icon={FootSteps}
              color="primary"
              size="large"
              className="ion-padding"
            ></IonIcon>

            <IonLabel>Steps</IonLabel>
            <IonInput
              className="metric-input ion-text-center"
              type="number"
              value={steps}
              placeholder=""
              onIonChange={(e) => setSteps(Number(e.detail.value))}
            />
            <IonButton
              size="default"
              slot="end"
              disabled={steps == null || steps === 0}
              onClick={logSteps}
            >
              Log
            </IonButton>
          </IonItem>
          <IonItem>
            <IonIcon
              icon={Waist}
              color="primary"
              size="large"
              className="ion-padding"
            ></IonIcon>

            <IonLabel className="ion-text-wrap">
              Waist <br></br>Circumference <p>cm</p>
            </IonLabel>
            <IonInput
              className="metric-input ion-text-center"
              type="number"
              value={waistCircumference}
              placeholder=""
              onIonChange={(e) => setWaistCircumference(Number(e.detail.value))}
            />
            <IonButton
              size="default"
              slot="end"
              disabled={waistCircumference == null || waistCircumference === 0}
              onClick={logWaistCircumference}
            >
              Log
            </IonButton>
          </IonItem>
          <IonItem>
            <IonIcon
              icon={Scale}
              color="primary"
              size="large"
              className="ion-padding"
            ></IonIcon>

            <IonLabel className="ion-text-wrap">
              Weight <p>lbs</p>
            </IonLabel>
            <IonInput
              className="metric-input ion-text-center"
              type="number"
              value={weight}
              placeholder=""
              onIonChange={(e) => setWeight(Number(e.detail.value))}
            />
            <IonButton
              size="default"
              slot="end"
              disabled={weight == null || weight === 0}
              onClick={logWeight}
            >
              Log
            </IonButton>
          </IonItem>
          <IonItem>
            <IonIcon
              icon={Calculator}
              color="primary"
              size="large"
              className="ion-padding"
            ></IonIcon>

            <IonLabel className="ion-text-wrap">
              BMI
            </IonLabel>
            <IonInput
              className="metric-input ion-text-center"
              type="number"
              value={bmi}
              placeholder=""
              onIonChange={(e) => setBmi(Number(e.detail.value))}
            />
            <IonButton
              size="default"
              slot="end"
              disabled={bmi == null || bmi === 0}
              onClick={logBMI}
            >
              Log
            </IonButton>
          </IonItem>
        </IonContent>
      </IonModal>
    </>
  );
}
