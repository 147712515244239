import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonPage,
} from "@ionic/react";

import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../lib/context";
import axios from "axios";
import Loader from "../../../components/Loader";
import { Browser } from "@capacitor/browser";
import firebase from "firebase/compat/app";
import "./Home.css";

const Home: React.FC = () => {
  const { user, profile } = useContext(UserContext);

  // function doRefresh(event: CustomEvent<RefresherEventDetail>) {
  //   setRefreshing(true);

  //   setTimeout(() => {
  //     setRefreshing(false);
  //     event.detail.complete();
  //   }, 2000);
  // }

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);
  // const ReformatDOB = () => {

  //   const [dob, setDob] = useState("");

  //   const dobOld = dob;
  //   const dobNew = reformatDOB(dobOld);

  //   return (
  //     <>
  //       <p>
  //         {dobOld} == {dobNew} // {dob}
  //       </p>
  //       <IonInput type='date' onIonChange={(e) => setDob(e.detail.value)}>Stuff</IonInput>
  //     </>
  //   );
  // };

  const openBrowser = async (url) => {
    await Browser.open({ url: url });
  };

  function getTelemedicineUrlSSO() {
    // console.log("getTelemedicineUrlSSO");

    const data = { walmartHealthId: profile.walmartHealthId };

    // https://us-central1-allied-wellness-dev.cloudfunctions.net/HelloToTheWorld
    // https://us-central1-allied-wellness-dev.cloudfunctions.net/GetWalmartHealthDashboardSSO
    //presentToast("middle", "Redirecting to Telemedicine...", "primary");

    // console.log('Telemedicine URL');

    //window.location.href = "https://www.google.com"
    const config = {
      method: "post",
      url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/WalmartHealth-GetDashboardSSO`,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //setLoading(false);
        openBrowser(response.data);
        //window.location.href = response.data
        // console.log(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        //presentToast("top", "An error has occurred", "danger");
        console.log(error);
      });
  }

  useEffect(() => {
    getTelemedicineUrlSSO();
  }, []);

  const signOutUser = () => {
    // CometChat.logout();
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
    //window.location.href = "/login";
  };

  return (
    <>
      <IonPage id="main">
        <IonContent>
          <IonCard className="telemedicineCard">
            <IonCardHeader>
              <IonCardTitle>
                Hello {profile.firstName} {profile.lastName}, welcome to Allied
                Wellness Telemedicine
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p>
                Being a member of Allied Wellness, you have access to
                Telemedicine services. Please click the button below to open the
                Telemedicine Dashboard.
              </p>
              <IonButton
                onClick={() => {
                  getTelemedicineUrlSSO();
                  setLoading(true);
                }}
              >
                Dashboard
              </IonButton>
              <br></br>
              <br></br>
              <p>
                Or sign out{" "}
                <a className="underline" onClick={signOutUser}>
                  here
                </a>
              </p>
            </IonCardContent>
          </IonCard>
          {/* <IonButton onClick={signOutUser}>Sign Out</IonButton> */}
          {loading ? <Loader /> : null}
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
