import {
  IonButton,
  IonButtons,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonText,
  IonToolbar,
} from "@ionic/react";
import "./style.css";
import { useEffect, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";

type Props = {
  uid: string;
  name: string;
  location: string;
  photo: string;
};

const CoachInfoLink: React.FC<Props> = ({ uid, name, location, photo }) => {
  const [coachData, setCoachData] = useState(null);
  const [viewCoachInfoOpen, setViewCoachInfoOpen] = useState(false);

  const getCoachData = async () => {
    getDoc(doc(getFirestore(), "users", uid)).then((doc) => {
      if (doc.exists()) {
        setCoachData(doc.data());
      } else {
        // console.log("No such document!");
      }
    });
  };

  useEffect(() => {
    if (viewCoachInfoOpen && coachData === null) {
      getCoachData();
    }
  }, [viewCoachInfoOpen, coachData]);

  return (
    <>
      <div className="wrapper-my-coach-session">
        <img src={photo} alt="" onClick={() => setViewCoachInfoOpen(true)} />
      </div>

      <IonLabel>
        <h2 className="ion-padding-top">
          {location} with{" "}
          <b onClick={() => setViewCoachInfoOpen(true)}>{name}</b>
        </h2>
      </IonLabel>

      <IonModal
        isOpen={viewCoachInfoOpen}
        onDidDismiss={() => setViewCoachInfoOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => setViewCoachInfoOpen(false)}>
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-text-center">
          {coachData && (
            <>
              <IonCardHeader className="ion-text-center">
                <div className="wrapper-my-coach">
                  <img src={coachData.photoURL} alt="" />
                </div>
              </IonCardHeader>
              <IonCardContent>
                <h1> {coachData.firstName + " " + coachData.lastName} </h1>
                <IonLabel>
                  {coachData.title}
                  <br></br>
                </IonLabel>
                <IonLabel className="qualifications">
                  {coachData.qualifications}
                  <br></br>
                  <br></br>
                </IonLabel>
                <IonText className="ion-text-center">
                  {" "}
                  {coachData.about} <br></br> <br></br>
                </IonText>
                {/* <IonButton className="ion-text-center" expand="full" shape="round" routerLink="/tabs/appointment/my-health-coach">
              Schedule a call
            </IonButton> */}
              </IonCardContent>
            </>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default CoachInfoLink;
