import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonProgressBar,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { UserContext } from "../../../lib/context";
import "./ProfileCreate.css";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import { chevronBack } from "ionicons/icons";

import SetUpProfile from "../../../components/HRA/SetUpProfile";
//import AddSleep from "../../components/HRA/WellnessSurvey/AddSleep";
import AddAddress from "../../../components/HRA/AddAddress";

import { Swiper, SwiperSlide } from "swiper/react";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

const ProfileCreate: React.FC = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [buttonText, setButtonText] = useState("Continue");
  const [numSlides, setNumSlides] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { user, profile } = useContext(UserContext);
  const [profileObj, setProfileObj] = useState(profile);
  const [slideChange, setSlideChange] = useState(false);

  const hraComplete = async () => {
    // console.log("hraComplete");
    profileObj.hraComplete = true;
    profileObj.accountSetupComplete = true;
    profileObj.hraCompleteDate = new Date();
    profileObj.tempPasswordChanged = profile.tempPasswordChanged;
    // console.log("profileObj", profileObj);

    const ref = doc(getFirestore(), "users", user.uid);
    await updateDoc(ref, profileObj);
  };

  const buttonHandler = (buttonDis) => {
    setButtonDisabled(buttonDis);
  };

  const profileHandler = (profileObject) => {
    setProfileObj(profileObject);
  };

  const checkIfLastSlide = () => {};

  const prevHandler = () => {
    swiperRef.slidePrev();
    setSlideIndex(swiperRef.realIndex);
  };

  const nextHandler = () => {
    swiperRef.slideNext();
    setSlideIndex(swiperRef.realIndex);
  };

  const signOutUser = () => {
    // CometChat.logout();
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
    //window.location.href = "/login";
  };

  useEffect(() => {
    setTimeout(() => {
      setNumSlides(swiperRef.slides.length - 1);
    }, 500);
  }, [swiperRef]);

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {slideIndex === 0 ? null : (
              <IonIcon
                slot="start"
                icon={chevronBack}
                size="large"
                color="primary"
                onClick={() => prevHandler()}
              />
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonProgressBar
        className="progessbar"
        value={slideIndex / numSlides}
      ></IonProgressBar>
      <Swiper
        onSwiper={(swiper) => setSwiperRef(swiper)}
        style={{ width: "95%", height: "75%" }}
        allowTouchMove={false}
        height={100}
        onSlideChange={() => {
          setSlideChange(!slideChange);
          checkIfLastSlide();
        }}
      >
        <SwiperSlide className="ion-padding-top ">
          <div className="">
            <SetUpProfile
              slides={swiperRef}
              slideIndex={slideIndex}
              buttonHandler={buttonHandler}
              profileHandler={profileHandler}
              profile={profileObj}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-no-swiping ion-padding-top">
          <AddAddress
            slides={swiperRef}
            slideIndex={slideIndex}
            buttonHandler={buttonHandler}
            profileHandler={profileHandler}
            profile={profileObj}
          />
        </SwiperSlide>
      </Swiper>
      {numSlides === slideIndex ? (
        <IonButton
          className="ion-margin continue-button"
          expand="block"
          onClick={() => hraComplete()}
        >
          Finish
        </IonButton>
      ) : (
        <IonButton
          className="ion-margin continue-button"
          onClick={nextHandler}
          expand="block"
          disabled={buttonDisabled}
        >
          Continue
        </IonButton>
      )}
    </IonContent>
  );
};

export default ProfileCreate;
