import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
//import spinner
import { useContext, useState } from "react";
import React from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { EmailAuthProvider } from "firebase/auth";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import { UserContext } from "../../../../lib/context";

const ChangePhone: React.FC = () => {
  //email uststate
  const { user, profile } = useContext(UserContext);
  const [phone, setPhone] = useState(profile.phone || "");
  const [validPhone, setValidPhone] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [presentAlert] = useIonAlert();


  const validatePhone = () => {
    var phoneHelper = document.getElementById("phoneHelp");
    var regex = /^\d{3}\-\d{3}\-\d{4}$/;

    if (phone === undefined || phone === "") {
      phoneHelper.innerHTML = "Phone number is required";
      setValidPhone(false);
      return false;
    }

    // make sure only accepts numbers
    else if (!regex.test(phone)) {
      phoneHelper.innerHTML = "Phone number must be in the format 123-456-7890";
      setValidPhone(false);
      return false;
    } else {
      phoneHelper.innerHTML = "";
      setValidPhone(true);
      return true;
    }
  };

  const updatePhone = async (e) => {
    e.preventDefault();
    if (validatePhone()) {
      const db = getFirestore();
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        phone: phone,
      }).then(() => {
        presentAlert({
          header: 'Success',
          message: 'Your phone number has been updated',
          buttons: ['OK'],
        })
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="tabs/home" />
          </IonButtons>

          <IonTitle className="mainPageTitle">Change Phone </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
   
              <form onSubmit={updatePhone}>
                <IonItem>
                  <IonLabel position="stacked">New Phone</IonLabel>
                  <IonInput
                    value={phone}
                    onIonChange={(e: any) => {
                      setPhone(e.detail.value);
                    }}
                    onIonBlur={validatePhone}
                  ></IonInput>
                  <p className="formHelp" id="phoneHelp"></p>
                </IonItem>

                

                <div  id="login-help"></div>

                <IonButton type="submit" expand="block" className="ion-padding" disabled={!validPhone}>
                  Update
                </IonButton>
              </form>
      </IonContent>
    </IonPage>
  );
};

export default ChangePhone;
