import { auth } from "../lib/Firebase";

export async function isUserBasic(): Promise<boolean> {
  await auth.currentUser?.getIdToken(true);

  const decodedToken = await auth.currentUser?.getIdTokenResult();

  if (decodedToken?.claims.stripeRole === "basic") {
    return true;
  }

  return false;
}

export async function isUserPremium(): Promise<boolean> {
  await auth.currentUser?.getIdToken(true);

  const decodedToken = await auth.currentUser?.getIdTokenResult();

  if (decodedToken?.claims.stripeRole === "premium") {
    return true;
  }

  return false;
}

export async function isUserStripe(): Promise<boolean> {
  await auth.currentUser?.getIdToken(true);

  const decodedToken = await auth.currentUser?.getIdTokenResult();


  return decodedToken?.claims.stripeRole ? true : false;
}

export async function userSubType() {
  await auth.currentUser?.getIdToken(true);

  const decodedToken = await auth.currentUser?.getIdTokenResult();


  return decodedToken?.claims.stripeRole;
}

