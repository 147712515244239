import React from "react";
import "../Popup.css";

const Popup_irregularHeartBeats = () => {
  return (
    <div className="ion-padding popup-wrapper">
      <p>
        The number displayed is the number of irregular heartbeats the scan
        detected in 30 seconds.
      </p>
      <p>
        An irregular heartbeat is one that occurs outside of your heart's normal
        rhythm. The beat may have occurred sooner than expected or your heart
        may have seemed to 'skip' a beat. Sometimes it can feel like a thump in
        your chest, or the feeling that your heart is beating quickly or
        fluttering.
      </p>
      <p>
        Irregular heartbeats are usually harmless. Most often they are triggered
        by things that increase adrenaline in the body like anxiety, caffeine,
        and nicotine.
      </p>
      <p>
        In rare cases, they could indicate a more serious condition like an
        electrolyte imbalance or a heart condition. You should consult your
        doctor if you have a persistent irregular heartbeat. You should seek
        immediate medical attention if the onset of irregular heartbeats is
        combined with other symptoms of a heart problem like dizziness, chest
        pain, or shortness of breath.
      </p>
    </div>
  );
};

export default Popup_irregularHeartBeats;
