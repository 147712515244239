import {
  IonItem,
  IonIcon,
  IonLabel,
  IonModal,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonButton,
} from "@ionic/react";
import "./style.css";
import { helpOutline, timer, timerOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import CommentedPost from "../../../Activity/CommentedPost";
import DownvoteItem from "../../../Activity/DownvoteItem";
import UpvoteItem from "../../../Activity/UpvoteItem";
import { query, collection, getFirestore, where, getDocs } from "firebase/firestore";
import { UserContext } from "../../../../../../lib/context";
import QuestionCard from "../../../QuestionCard";

type Props = {};

const MyQuestions: React.FC<Props> = () => {
  const [myQuestionsOpen, setMyQuestionsOpen] = useState(false);

  const { koroPublicAvatar } = useContext(UserContext);

  const [questionsData, setQuestionsData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const getQuestions = async () => {
    var questions = [];

    var q = query(
      collection(getFirestore(), "koroQuestions"),
      where("poster", "==", koroPublicAvatar.uid)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      questions.push({ id: doc.id, ...doc.data() });
    });

    // sort questions by timestamp.seconds in ascending order
    questions.sort((a, b) => {
      return b.dateCreated.seconds - a.dateCreated.seconds;
    });

    setQuestionsData(questions);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const refreshHandler = () => {
    getQuestions();
  };

  return (
    <>
      <IonItem
        detail={true}
        className="noBackground"
        lines="none"
        onClick={() => {
          setMyQuestionsOpen(true);
        }}
      >
        <IonIcon slot="start" icon={helpOutline} />
        <IonLabel>My Questions</IonLabel>
      </IonItem>
      <IonModal isOpen={myQuestionsOpen}>
        <IonHeader>
          <IonToolbar>
            {/* <IonImg src="koroLogo.png" className="koroLogoHeader" /> */}
            <IonTitle>My Questions</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={() => setMyQuestionsOpen(false)}>
                Back
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="backgroundColor"> {questionsData.length === 0 && <p className="ion-text-center">You have not posted any questions yet...</p>
        }
        {questionsData.map((question, index) => {
          return <QuestionCard question={question} refresh={refresh} key={question.id} refreshHandler={refreshHandler} />;
        })} </IonContent>
      </IonModal>
    </>
  );
};

export default MyQuestions;
