import {
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
//import spinner
import React, { useEffect, useState } from "react";
import "../HRAStyle.css";

type Props = {
  slides;
  buttonHandler;
  profileHandler;
  profile;
  slideIndex;
};

const AddFeelingDown: React.FC<Props> = ({
  slides,
  buttonHandler,
  profileHandler,
  profile,
  slideIndex,
}) => {
  const [answer, setAnswer] = useState(profile.WellnessFeelingDown);

  const handleColor = (item) => {
    if (item === "Not at all") {
      item = "notAtAll";
    }

    if (item === "Several Days") {
      item = "severalDays";
    }

    if (item === "More than half the days") {
      item = "moreThanHalfTheDays";
    }

    if (item === "Nearly everyday") {
      item = "everyDay";
    }

    const notAtAll = document.getElementById("FeelingDownNotAtAll");
    const severalDays = document.getElementById("FeelingDownSeveralDays");
    const moreThanHalfTheDays = document.getElementById(
      "FeelingDownMoreThanHalfTheDays"
    );
    const almostEveryDay = document.getElementById("FeelingDownEveryDay");

    notAtAll.classList.remove("pickedItem");
    severalDays.classList.remove("pickedItem");
    moreThanHalfTheDays.classList.remove("pickedItem");
    almostEveryDay.classList.remove("pickedItem");

    if (item === "notAtAll") {
      notAtAll.classList.add("pickedItem");
    }

    if (item === "severalDays") {
      severalDays.classList.add("pickedItem");
    }

    if (item === "moreThanHalfTheDays") {
      moreThanHalfTheDays.classList.add("pickedItem");
    }

    if (item === "everyDay") {
      almostEveryDay.classList.add("pickedItem");
    }
  };

  const validateValues = () => {
    if (
      answer === "" ||
      answer === undefined ||
      answer === null ||
      answer === " "
    ) {
      buttonHandler(true);
    } else {
      buttonHandler(false);
    }
  };

  useEffect(() => {
    if (slideIndex === 7) {
      validateValues();
      handleColor(answer);
    }
  }, [answer, slideIndex]);

  return (
    <>
      <IonCardTitle class="ion-text-start wellnesSlideTitle">
        Over the last 2 weeks, how often have you been bothered by feeling down,
        depressed, or hopeless?
      </IonCardTitle>
      <div className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem
                className="mainGoalItem "
                id="FeelingDownNotAtAll"
                lines="none"
                fill="outline"
                onClick={(e) => {
                  setAnswer("Not at all");
                  handleColor("notAtAll");
                  profile.WellnessFeelingDown = "Not at all";
                  profileHandler(profile);
                }}
              >
                <IonLabel className="ion-text-center"> Not at all </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem
                className="mainGoalItem "
                id="FeelingDownSeveralDays"
                lines="none"
                fill="outline"
                onClick={(e) => {
                  setAnswer("Several Days");
                  handleColor("severalDays");
                  profile.WellnessFeelingDown = "Several Days";
                  profileHandler(profile);
                }}
              >
                <IonLabel className="ion-text-center"> Several days </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem
                className="mainGoalItem "
                id="FeelingDownMoreThanHalfTheDays"
                lines="none"
                fill="outline"
                onClick={(e) => {
                  setAnswer("More than half the days");
                  handleColor("moreThanHalfTheDays");
                  profile.WellnessFeelingDown = "More than half the days";
                }}
              >
                <IonLabel className="ion-text-center">
                  {" "}
                  More than half the days{" "}
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem
                className="mainGoalItem "
                id="FeelingDownEveryDay"
                lines="none"
                fill="outline"
                onClick={(e) => {
                  setAnswer("Nearly everyday");
                  handleColor("everyDay");
                  profile.WellnessFeelingDown = "Nearly everyday";
                  profileHandler(profile);
                }}
              >
                <IonLabel className="ion-text-center">
                  {" "}
                  Nearly everyday{" "}
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default AddFeelingDown;
