import {
  IonItem,
  IonAvatar,
  IonLabel,
  IonCol,
  IonIcon,
  useIonAlert,
} from "@ionic/react";
import "./style.css";
import { useContext, useState } from "react";
import { UserContext } from "../../../../../lib/context";
import Avatar from "../Avatar";
import AvatarMini from "../AvatarMini";
import { trashBin } from "ionicons/icons";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

type Props = {
  avatar;
  name;
  time;
  comment;
  replyHandler;
  publicAvatarUid;
  expert;
  id;
  commentObj;
  allowReply;
};

const Reply: React.FC<Props> = ({
  avatar,
  name,
  time,
  comment,
  replyHandler,
  publicAvatarUid,
  expert,
  commentObj,
  allowReply,
  id,
}) => {
  const { koroPublicAvatar } = useContext(UserContext);

  const [commentData, setCommentData] = useState(commentObj);
  const [presentAlert] = useIonAlert();

  const renderAtInComment = (comment) => {
    let commentArray = comment.split(" ");
    let newCommentArray = [];
    commentArray.forEach((word) => {
      if (word.startsWith("@")) {
        newCommentArray.push(<span className="bold primary">{word}</span>);
      } else {
        newCommentArray.push(word);
      }
    });
    // return newCommentArray;
    return newCommentArray.map((word) => {
      return <>{word} </>;
    });
  };

  const deleteComment = async () => {
    const docRef = doc(getFirestore(), "koroComments", commentData.id);

    await updateDoc(docRef, {
      deleted: true,
    }).then(() => {
      // console.log("Document successfully deleted!");
      setCommentData({ ...commentData, deleted: true });
    });
  };

  if (commentData.deleted) {
    return <></>;
  }

  return (
    <IonItem className="koroQuestionAnswerSubHeaderItem" lines="none">
      {/* <IonAvatar className="koroQuestionAnswerAvatar">
        <img src={avatar} />
      </IonAvatar> */}
      <AvatarMini
        src={avatar}
        publicAvatarUid={publicAvatarUid}
        expert={expert}
      />

      <IonLabel>
        <h2>
          {" "}
          <span className="bold">{name}</span> ·{" "}
          {expert && (
            <>
              <span className="expert">Expert</span> ·
            </>
          )}
          <span className="koroQuestionAnswerTime">{time}</span>
        </h2>

        <p className="ion-text-wrap">{renderAtInComment(comment)}</p>
        {publicAvatarUid !== koroPublicAvatar.uid && allowReply && (
          <p
            className="ion-text-wrap replyLink"
            onClick={() => {
              replyHandler({ publicAvatarUid, name, parentCommentId: id });
            }}
          >
            Reply
          </p>
        )}
      </IonLabel>
      {publicAvatarUid === koroPublicAvatar.uid && (
        <IonCol size="auto">
        <IonIcon
          icon={trashBin}
          size="small"
          onClick={() =>
            presentAlert({
              header: "Delete Comment",
              message: "Are you sure you want to delete this comment?",
              buttons: [
                {
                  text: "Cancel",
                  role: "cancel",
                  handler: () => {
                    // console.log("Cancel clicked");
                  },
                },
                {
                  text: "OK",
                  role: "confirm",
                  handler: () => {
                    deleteComment();
                  },
                },
              ],
            })
          }
        />
        </IonCol>
      )}
    </IonItem>
  );
};

export default Reply;
