//import spinner
import { useContext } from "react";
import React from "react";
import "firebase/compat/auth";
import { UserContext } from "../../lib/context";
import "./TeleMedicine.css";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonText,
  IonItem,
  IonButton,
  IonImg,
} from "@ionic/react";

import img from "../../assets/images/logosCombined.png"

const Telemedicine: React.FC = () => {
  const { user, profile } = useContext(UserContext);

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="tabs/home" />
            </IonButtons>
            <IonTitle className="mainPageTitle">Telemedicine</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className=" ion-text-center">
          <IonImg
            src={img}
            alt="Telemedicine"
            className="sesameLogTMImage"
          ></IonImg>
          <br></br>
          <IonItem className="ion-padding-end">
            <IonLabel className="ion-no-margin ion-margin-bottom">
              <h2 className="ion-text-wrap ion-text-center">
                Here to connect you directly with medical professionals.
              </h2>
              <p className="ion-text-wrap ion-text-center">
                Activate your account online to get started!
              </p>
            </IonLabel>
          </IonItem>
          <br></br>
          <IonItem lines="none" className="ion-text-wrap">
            <IonLabel>
              <h2 className="ion-text-wrap">
                <span className="bold">STEP 1</span>: ACTIVATE YOUR TELEMEDICINE
                ACCOUNT
              </h2>
              <p className="ion-text-wrap">
                To activate your Telemedicine account with Sesame, please click
                the Activate Account button below and follow the instructions.
              </p>
            </IonLabel>
          </IonItem>
          <IonButton
            href="https://sesamecare.com/join/alliedwellness"
            target="_blank"
            className="sesamePurple"
          >
            Activate Account Now
          </IonButton>
          <br></br>
          <br></br>
          <br></br>
          <IonItem lines="none">
            <IonLabel>
              <h2 className="ion-text-wrap">
                <span className="bold">STEP 2</span>: TELEMEDICINE ACCESS
              </h2>
              <p className="ion-text-wrap">
                Once your account activation is complete, click the button below
                to log in to your Sesame account and book an appointment.
              </p>
            </IonLabel>
          </IonItem>
          <IonButton
            href="https://sesamecare.com/partners/alliedwellness"
            target="_blank"
            className="sesamePurple"

          >
            Book an Appointment
          </IonButton>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <IonItem lines="none">
            <IonLabel>
              <p className="ion-text-wrap">
                <span className="bold">Disclaimer</span>: As an Allied Wellness
                member, you can book online appointments with top-rated
                physicians at no cost. However, Sesame may offer services that
                are not included in your Allied Wellness membership.
                Additionally, Sesame may update its website features, offerings,
                and pricing at any time. Please note that any services with a
                price are not covered by Allied Wellness, and you will be solely
                responsible for paying those charges.
              </p>
            </IonLabel>
          </IonItem>
          <br></br>
          <br></br>
          <IonItem lines="none">
            <IonLabel>
              <p className="ion-text-wrap">
                <span className="bold">Terms & Conditions</span>: Physicians can
                diagnose many common non-emergency condition symptoms, recommend
                treatment options, and prescribe medication when medically
                appropriate. Account registration is required prior to
                requesting a consultation. Diagnostic consultations are
                available by phone or video for evaluations, diagnosis, and
                prescriptions if appropriate. Consultations by phone or video
                are free of charge. During the consultation scheduling process,
                you will be requested to update your health record. Your medical
                history provides doctors with the information they need to make
                an accurate diagnosis. Consultations will take place within 2
                hours of the initial request or can be scheduled for a specific
                time. Disclosure: Provider does not prescribe DEA controlled
                substances, lifestyle drugs, and certain other drugs which may
                be harmful because of their potential for abuse. Provider does
                not guarantee that a prescription will be written. Provider
                physicians reserve the right to deny care for potential misuse
                of services.
              </p>
            </IonLabel>
          </IonItem>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Telemedicine;
