import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";
import "./Home.css";
import GoalsTodo from "../../../components/Goals/Goals";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../lib/context";
import LogMeal from "../../../components/Journal/LogMeal2";
import LogExercise from "../../../components/Journal/LogExercise2";
import LogMood from "../../../components/Journal/LogMood2";
import LogMetrics from "../../../components/Journal/LogMetrics2";
import FaceScan from "../../../assets/icons/fork-and-spoon.svg";
import Run from "../../../assets/icons/run.svg";
import Metrics from "../../../assets/icons/metrics.svg";
import Mood from "../../../assets/icons/mood.svg";
import RenderSessionUpcoming from "../../../components/Appointment/RenderSessionUpcoming";
import { InactiveAccountHeader } from "../../../components/InactiveAccountHeader";

import "./Home.css";
import { getCurrentDate } from "../../../HelperFunctions/dates";
import { PushNotifications } from "@capacitor/push-notifications";
import LogGlucose from "../../../components/Journal/LogGlucose";

const Home: React.FC = () => {
  const [appointment, setAppointment] = useState([]);
  const [text, setText] = useState<string>();
  const [number, setNumber] = useState<number>();
  const [date, setDate] = useState(getCurrentDate());
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { user, profile } = useContext(UserContext);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setRefreshing(true);

    setTimeout(() => {
      setRefreshing(false);
      event.detail.complete();
    }, 2000);
  }

  useEffect(() => {
    // console.log("trying to get push notif ");
    PushNotifications.requestPermissions().then((res) => {
      // console.log("res.receive", res.receive);
      // console.log("res0", res);

      if (res.receive === "denied") {
        // showToast('Push Notification permission denied', "WAD");
      } else {
        // showToast('Push Notification permission granted',"awd");
      }
    }).catch((err) => {
      console.log("err", err);
    });
  }, []);

  // get url 

  return (
    <>
      <IonPage>
        <InactiveAccountHeader />
        <IonHeader>
          <IonToolbar className="mainHeaderBackground">
            <IonButtons slot="start">
              <IonMenuToggle className="white">
                <IonMenuButton className="white" />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle className="mainPageTitleTEst">Home</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <LogMeal date={date} user={user} />
          <LogExercise date={date} user={user} />
          <LogMood date={date} user={user} />
          <LogMetrics date={date} user={user} />

          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          {/* <IonButton onClick={test}>Test</IonButton> */}

          <IonItem className="new-section" lines="none">
            <p className="new-section-title">SESSIONS</p>
          </IonItem>
          <div className="ion-padding center-home">
            <RenderSessionUpcoming refresh={refreshing} />
          </div>

          <GoalsTodo refresh={refreshing} />

          <IonItem className="new-section" lines="none">
            <p className="new-section-title">journal</p>
          </IonItem>
          <IonGrid className="ion-padding-top">
            <IonRow>
              <IonCol>
                <div className="journal-square meal ion-text-center" id="open-log-meal2">
                  <IonIcon className="log-icon" icon={FaceScan}></IonIcon>
                  <br></br>
                  <IonLabel className="jour">Log Meal</IonLabel>
                </div>
              </IonCol>
              <IonCol>
                <div
                  className="journal-square activity ion-text-center"
                  id="open-log-exercise2"
                >
                  <IonIcon className="log-icon" icon={Run}></IonIcon>
                  <br></br>
                  <IonLabel className="jour">Log Activity</IonLabel>
                </div>
              </IonCol>
              <IonCol>
                <div className="journal-square metrics ion-text-center" id="open-log-metrics2">
                  <IonIcon className="log-icon" icon={Metrics}></IonIcon>
                  <br></br>
                  <IonLabel className="jour">Log Metrics</IonLabel>
                </div>
              </IonCol>
              <IonCol>
                <div className="journal-square mood ion-text-center" id="open-log-mood2">
                  <IonIcon className="log-icon" icon={Mood}></IonIcon>
                  <br></br>
                  <IonLabel className="jour">Log Mood</IonLabel>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <LogGlucose />
              </IonCol>
            </IonRow>
          </IonGrid>

          {/* <IonGrid>
            <IonRow>
              <IonCol>
                <div className="journal-square meal" id="open-log-meal2">
                  <IonIcon className="log-icon" icon={FaceScan}></IonIcon>
                  <br></br>
                  <IonLabel className="jour">Log Meal</IonLabel>
                </div>
              </IonCol>
              <IonCol>
                <div
                  className="journal-square activity"
                  id="open-log-exercise2"
                >
                  <IonIcon className="log-icon" icon={Run}></IonIcon>
                  <br></br>
                  <IonLabel className="jour">Log Activity</IonLabel>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="journal-square metrics" id="open-log-metrics2">
                  <IonIcon className="log-icon" icon={Metrics}></IonIcon>
                  <br></br>
                  <IonLabel className="jour">Log Metrics</IonLabel>
                </div>
              </IonCol>
              <IonCol>
                <div className="journal-square mood" id="open-log-mood2">
                  <IonIcon className="log-icon" icon={Mood}></IonIcon>
                  <br></br>
                  <IonLabel className="jour">Log Mood</IonLabel>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid> */}
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
