import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import "firebase/compat/auth";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { doc, getFirestore, getDoc } from "firebase/firestore";
import { UserContext } from "../../../lib/context";

const MyCoach: React.FC = () => {
  //email uststate
  const [coach, setCoach] = useState<any>({});
  const { user, profile } = useContext(UserContext);

  //get coach document from firestore
  const getCoach = async () => {
    const docRef = doc(getFirestore(), "users", profile.coach);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setCoach(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
    }
  };

  const clearIonicHistory = () => {
    //clear history
    //log history
    // go to /tabs/appointment
    setTimeout(() => { window.location.reload(); }, 3000);

  };

  useEffect(() => {
    getCoach();
  }, []);

  useCalendlyEventListener({
    onEventScheduled: (e) => clearIonicHistory(),
  });

  document.addEventListener("ionBackButton", (ev) => {
    // console.log("Back button pressed!");
})

  return (
    <>
    <IonPage>
      <IonHeader>
        <IonToolbar className="mainHeaderBackground">
          <IonButtons slot="start" className="mainPageTitleTEst">
            <IonBackButton
              className="mainPageTitleTEst"
              defaultHref="/tabs/appointment"
            />

            <IonRouterLink href="/tabs/appointment" slot="start">
            </IonRouterLink>
          </IonButtons>
          <IonTitle className="mainPageTitleTEst">My Coach</IonTitle>
        </IonToolbar>
      </IonHeader>
<IonContent>
        <InlineWidget
          url={
            coach.calendlyLink
              ? coach.calendlyLink +
                `?hide_gdpr_banner=1&email=${user.email}&name=${profile.firstName}%20${profile.lastName}`
              : "hh"
          }
          pageSettings={{
            hideLandingPageDetails: true,
            hideEventTypeDetails: true,
            hideGdprBanner: true,
            primaryColor: "456673",
            textColor: "456673",
          }}
          
        />
        </IonContent>
        </IonPage>
      </>
  );
};

export default MyCoach;
