import { callOutline, heartOutline, homeOutline, lockClosedOutline, mailOutline, personOutline } from "ionicons/icons";

export interface Settings {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
  }
  
  export const SettingsList: Settings[] = [
    {
      title: "Change Password",
      url: "/change-password",
      iosIcon: lockClosedOutline,
      mdIcon: lockClosedOutline,
    },
    {
      title: "Email",
      url: "/change-email",
      iosIcon: mailOutline,
      mdIcon: mailOutline,
    },
    {
      title: "Phone",
      url: "/change-phone",
      iosIcon: callOutline,
      mdIcon: callOutline,
    },
    { title: "Health Data",
      url: "/change-health-data",
      iosIcon: heartOutline,
      mdIcon: heartOutline,
    },
    {
      title: "Name",
      url: "/change-name",
      iosIcon: personOutline,
      mdIcon: personOutline,
    },
    {
      title: "Address",
      url: "/change-address",
      iosIcon: homeOutline,
      mdIcon: homeOutline,
    },
  ];