import { useState } from "react";
import "./style.css";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
} from "@ionic/react";
import { addDoc, collection, getFirestore } from "firebase/firestore";

type Props = {};

const EmailHelp: React.FC<Props> = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [groupName, setGroupName] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      name,
      email,
      message,
      groupName,
    };

    addDoc(collection(getFirestore(), "supportRequest"), data)
      .then(() => {
        // console.log("Document successfully written!");
        setFormSubmitted(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  return (
    <>
      <a onClick={() => setPopupOpen(true)}>here</a>
      <IonModal isOpen={popupOpen} onDidDismiss={() => setPopupOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Support</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setPopupOpen(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {formSubmitted ? (
            <>
            <IonLabel>
              <p className="ion-text-center">
                We have received your submission and will get back to you as soon as possible!
              </p>
            </IonLabel>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <IonItem lines="full">
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  autocomplete="email"
                  value={email}
                  onIonChange={(e) => setEmail(e.detail.value!)}
                  required={true}
                ></IonInput>
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="floating">Name</IonLabel>
                <IonInput
                  value={name}
                  required={true}
                  onIonChange={(e) => setName(e.detail.value!)}
                  autocomplete="name"
                ></IonInput>
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="floating">Group Name</IonLabel>
                <IonInput
                  value={groupName}
                  onIonChange={(e) => setGroupName(e.detail.value!)}
                ></IonInput>
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="floating">Message</IonLabel>
                <IonTextarea
                  value={message}
                  onIonChange={(e) => setMessage(e.detail.value!)}
                ></IonTextarea>
              </IonItem>
              <IonButton
                type="submit"
                expand="full"
                shape="round"
                className="ion-margin-top"
              >
                {loading ? "Submitting..." : "Submit"}
              </IonButton>
            </form>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default EmailHelp;
