import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
  doc,
  writeBatch,
  updateDoc,
} from "firebase/firestore";
import { UserContext } from "../../../../lib/context";
import "firebase/compat/auth";
import { Browser } from "@capacitor/browser";

const UpdateAddress = ({ slides }) => {
  const { user, profile } = useContext(UserContext);

  const [addr1, setAddr1] = useState(profile ? profile.addr1 : "");
  const [addr2, setAddr2] = useState(profile ? profile.addr2 : "");
  const [city, setCity] = useState(profile ? profile.city : "");
  const [state, setState] = useState(profile ? profile.state : "");
  const [zip, setZip] = useState(profile ? profile.zip : "");

  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  const updateAddressData = async () => {
    const ref = doc(getFirestore(), "users", user.uid);
    await updateDoc(ref, {
      addr1: addr1,
      addr2: addr2,
      city: city,
      state: state,
      zip: zip,
    }).then(() => {
      setUpdateSuccessful(true);
    });
    // // Create refs for both documents
    // const userDoc = doc(getFirestore(), "users", user.uid);

    // // Commit both docs together as a batch write.
    // const batch = writeBatch(getFirestore());

    // batch.set(userDoc, {
    //   role: "member",
    //   dob: dob,
    //   hraComplete: false,
    //   uid: user.uid,
    //   firstName: firstName,
    //   lastName: lastName,
    //   email: user.email,
    //   active: true,
    //   coach: coach,
    //   photoURL:
    //     "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/default.png?alt=media&token=c8450ca6-7ef6-49a3-95ff-2246452d62cc",
    // });
    // //batch.set(userDoc, { age: 20, weight: weight, uid: userData.user.uid, role: 'member', email: userData.user.email });

    // //Add the user to the database
    // await batch
    //   .commit()
    //   .then(() => {})
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const openBrowser = async (url) => {
    await Browser.open({ url: url });
  };

  const states = [
    { name: "None", value: "" },

    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const renderStates = states.map((state) => (
    <IonSelectOption key={state.abbreviation} value={state.name}>
      {state.name}
    </IonSelectOption>
  ));

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="tabs/home" />
            </IonButtons>

            <IonTitle className="mainPageTitle">Update Address</IonTitle>
          </IonToolbar>
        </IonHeader>

        {updateSuccessful ? (
          <IonCard>
            <IonCardContent>
                Address was updated successfully to:<br></br>
                {addr1} {addr2} {city}, {state} {zip}
            </IonCardContent>
          </IonCard>
        ) : (
          <>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Address 1</IonLabel>
                    <IonInput
                      autoCapitalize="on"
                      type="text"
                      value={addr1}
                      onIonChange={(e: any) => {
                        setAddr1(e.detail.value);
                      }}
                    />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Address 2</IonLabel>
                    <IonInput
                      autoCapitalize="on"
                      type="text"
                      value={addr2}
                      onIonChange={(e: any) => {
                        setAddr2(e.detail.value);
                      }}
                    />
                    <p className="formHelp" id="lastNameHelpH"></p>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">City</IonLabel>
                    <IonInput
                      autoCapitalize="on"
                      type="text"
                      value={city}
                      onIonChange={(e: any) => {
                        setCity(e.detail.value);
                      }}
                    />
                    <p className="formHelp" id="lastNameHelpH"></p>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">State</IonLabel>
                    <IonSelect
                      value={state}
                      onIonChange={(e) => {
                        setState(e.detail.value);
                      }}
                    >
                      {renderStates}
                    </IonSelect>
                    <p className="formHelp" id="dobHelp"></p>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">ZIP</IonLabel>
                    <IonInput
                      autoCapitalize="on"
                      type="text"
                      value={zip}
                      onIonChange={(e: any) => {
                        setZip(e.detail.value);
                      }}
                    />
                    <p className="formHelp" id="lastNameHelpH"></p>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        )}

        {updateSuccessful ? (
           <IonButton
           onClick={() => {
             setUpdateSuccessful(false);
           }}
           className="firstButton"
           expand="block"
         >
           Update Again
         </IonButton>
        ) : (
          <>
            {" "}
            <IonButton
              onClick={() => {
                updateAddressData();
              }}
              className="firstButton"
              expand="block"
            >
              Update
            </IonButton>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default UpdateAddress;
