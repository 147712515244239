// tremplate tsx file
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import { IonHeader, IonToolbar, IonTitle, IonIcon, IonButton, IonButtons, IonModal } from "@ionic/react";
import { profile } from "console";
import { closeOutline, sadOutline, warningOutline } from "ionicons/icons";
import { UserContext } from "../../lib/context";
import firebase from "firebase/compat/app";
import { Browser } from "@capacitor/browser";


export const InactiveAccountHeader = () => {
  const [warningOpen, setWarningOpen] = useState<boolean>(false);
  const { user, profile } = useContext(UserContext);

  const signOutUser = () => {
    // CometChat.logout();
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        window.location.href = "/login";
      })
      .catch((error) => {
        // An error happened.
      });
    //window.location.href = "/login";
  };

  const openBrowser = async (url) => {
    await Browser.open({ url: url });
  };

  
  return (
    <>
      {profile.active ? null : (
        <IonHeader color="danger" onClick={() => setWarningOpen(true)}>
          <IonToolbar color="danger"  className='ion-text-center'>
            <IonTitle  className='ion-text-center'>
              <IonIcon size="large" icon={warningOutline} className='ion-text-center'></IonIcon>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
      )}
      
      <IonModal isOpen={warningOpen}>
          {/* <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setWarningOpen(false)}>
                  <IonIcon icon={closeOutline}></IonIcon>
                </IonButton>
              </IonButtons>
              <IonTitle>Inactive Account</IonTitle>
            </IonToolbar>
          </IonHeader> */}
          <IonHeader>
            <IonToolbar className="mainHeaderBackground">
              <IonButtons slot="start">
                <IonButton onClick={() => setWarningOpen(false)}>
                  <IonIcon className="mainPageTitleTEst" icon={closeOutline}></IonIcon>
                </IonButton>
              </IonButtons>
              <IonTitle className="mainPageTitleTEst">inactive account</IonTitle>
            </IonToolbar>
          </IonHeader>
          <>
            <div className="noSubContainer ion-padding ion-text-center">
              <IonIcon icon={sadOutline} className="noSubIcon"></IonIcon>
              <h1>Account No Longer Active</h1>
           
              <br></br>
              <br></br>
              <IonButton expand="block" shape="round" routerLink="/manage-subscription" onClick={() => setWarningOpen(false)}>Subscriptions</IonButton>
              <IonButton expand="block" fill="clear" onClick={signOutUser}>
                Log Out
              </IonButton>
            </div>
          </>
        </IonModal>
    </>
  );
};
