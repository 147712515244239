/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
//import spinner
import React, { useEffect, useState } from "react";
import "./HRAStyle.css";
import "firebase/compat/auth";
import { femaleOutline, maleOutline } from "ionicons/icons";

type Props = {
  slides;
  buttonHandler;
  profileHandler;
  profile;
  slideIndex;
};

const AddHealthInfo: React.FC<Props> = ({
  slides,
  buttonHandler,
  profileHandler,
  profile,
  slideIndex,
}) => {
  const [smoker, setSmoker] = useState(profile ? profile.smoker : "");
  const [bloodPressureMeds, setBloodPressureMeds] = useState(
    profile ? profile.bloodPressureMeds : ""
  );
  const [hypertension, setHypertension] = useState(
    profile ? profile.hypertension : ""
  );
  const [diabetes, setDiabetes] = useState(profile ? profile.diabetes : "");


  const validateSmoker = () => {
    if (
      smoker === "" ||
      smoker === undefined ||
      smoker === null ||
      smoker === " "
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validateBloodPressureMeds = () => {
    if (
      bloodPressureMeds === "" ||
      bloodPressureMeds === undefined ||
      bloodPressureMeds === null ||
      bloodPressureMeds === " "
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validateHypertension = () => {
    if (
      hypertension === "" ||
      hypertension === undefined ||
      hypertension === null ||
      hypertension === " "
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validateDiabetes = () => {
    if (
      diabetes === "" ||
      diabetes === undefined ||
      diabetes === null ||
      diabetes === " "
    ) {
      return false;
    } else {
      return true;
    }
  };





  const validateValues = () => {
    if (
      validateSmoker() &&
      validateBloodPressureMeds() &&
      validateHypertension() &&
      validateDiabetes()
    ) {
      buttonHandler(false);
    } else {
      buttonHandler(true);
    }
  };

  useEffect(() => {
    if (slideIndex === 4) {
      validateValues();
    }
  }, [smoker, bloodPressureMeds, hypertension, diabetes, slideIndex]);

  return (
    <>
      <IonCardTitle class="ion-text-start slideTitle">
        Let's get some health information, {profile ? profile.firstName : null}
      </IonCardTitle>
      <IonGrid>
        <IonRow>
          <IonCol>
            <p className="ion-padding questionP">
              Do you currently use tobacco products?
            </p>
            <IonSegment
              value={smoker}
              onIonChange={(e) => {
                setSmoker(e.detail.value);
                profile.smoker = e.detail.value;
                profileHandler(profile);
              }}
            >
              <IonSegmentButton value="yes">
                <IonLabel>Yes</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="no">
                <IonLabel>No</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <p className="ion-padding questionP">
              Do you take blood pressure medication?
            </p>
            <IonSegment
              value={bloodPressureMeds}
              onIonChange={(e) => {
                setBloodPressureMeds(e.detail.value);
                profile.bloodPressureMeds = e.detail.value;
                profileHandler(profile);
              }}
            >
              <IonSegmentButton value="yes">
                <IonLabel>Yes</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="no">
                <IonLabel>No</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <p className="ion-padding questionP">Do you have Hypertension (high blood pressure)?</p>
            <IonSegment
              value={hypertension}
              onIonChange={(e) => {
                setHypertension(e.detail.value);
                profile.hypertension = e.detail.value;
                profileHandler(profile);
              }}
            >
              <IonSegmentButton value="yes">
                <IonLabel>Yes</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="no">
                <IonLabel>No</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <p className="ion-padding questionP">Do you have Diabetes?</p>
            <IonSegment
              value={diabetes}
              onIonChange={(e) => {
                setDiabetes(e.detail.value);
                profile.diabetes = e.detail.value;
                profileHandler(profile);
              }}
            >
              <IonSegmentButton value="no">
                <IonLabel>No</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="type1">
                <IonLabel>Type 1</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="type2">
                <IonLabel>Type 2</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default AddHealthInfo;

// import {
//   IonButton,
//   IonCardTitle,
//   IonCol,
//   IonContent,
//   IonGrid,
//   IonLabel,
//   IonPage,
//   IonRow,
//   IonSegment,
//   IonSegmentButton,
// } from "@ionic/react";
// import { doc, getFirestore, updateDoc } from "firebase/firestore";
// //import spinner
// import React, { useContext, useState } from "react";
// import { UserContext } from "../../lib/context";
// import "./HRAStyle.css";

// const AddHealthInfo = ({ slides }) => {
// const { profile, user } = useContext(UserContext);
// const [smoker, setSmoker] = useState(profile ? profile.smoker : "");
// const [bloodPressureMeds, setBloodPressureMeds] = useState(
//   profile ? profile.bloodPressureMeds : ""
// );
// const [hypertension, setHypertension] = useState(
//   profile ? profile.hypertension : ""
// );
// const [diabetes, setDiabetes] = useState(profile ? profile.diabetes : "");
// const [validSmoker, setValidSmoker] = useState(false);
// const [validBloodPressureMeds, setValidBloodPressureMeds] = useState(false);
// const [validHypertension, setValidHypertension] = useState(false);
// const [validDiabetes, setValidDiabetes] = useState(false);

//   // get slide ref
//   const changeDirection = async (direction: string) => {
//     const swiper = await slides.current.getSwiper();
//     if (direction === "next") {
//       swiper.slideNext();
//     } else if (direction === "prev") {
//       swiper.slidePrev();
//     }
//   };

//   // if access is facescan only, will change accountSetup to true after this slide
//   const addHealthData = async () => {
//     if (
//       validBloodPressureMeds &&
//       validDiabetes &&
//       validHypertension &&
//       validSmoker
//     ) {
//       const ref = doc(getFirestore(), "users", user.uid);
//       var accountSetupComplete = false;
//       if(profile.access === "facescanOnly"){
//         accountSetupComplete = true;
//       }
//       await updateDoc(ref, {
//         smoker: smoker,
//         bloodPressureMeds: bloodPressureMeds,
//         hypertension: hypertension,
//         diabetes: diabetes,
//         accountSetupComplete: accountSetupComplete,
//       });
//     }
//   };

//   return (
//     <IonPage>
//       <IonContent fullscreen>
// <IonCardTitle class="ion-text-start slideTitle">
//   Let's get some health information,{" "}
//   {profile ? profile.firstName : null}
// </IonCardTitle>
// <IonGrid>
//   <IonRow>
//     <IonCol>
//       <p className="ion-padding questionP">
//         Do you currently use tobacco products?
//       </p>
//       <IonSegment
//         onIonChange={(e) => {
//           setSmoker(e.detail.value);
//           setValidSmoker(true);
//         }}
//       >
//         <IonSegmentButton value="yes">
//           <IonLabel>Yes</IonLabel>
//         </IonSegmentButton>
//         <IonSegmentButton value="no">
//           <IonLabel>No</IonLabel>
//         </IonSegmentButton>
//       </IonSegment>
//     </IonCol>
//   </IonRow>
//   <IonRow>
//     <IonCol>
//       <p className="ion-padding questionP">
//         Do you take blood pressure medication?
//       </p>
//       <IonSegment
//         onIonChange={(e) => {
//           setBloodPressureMeds(e.detail.value);
//           setValidBloodPressureMeds(true);
//         }}
//       >
//         <IonSegmentButton value="yes">
//           <IonLabel>Yes</IonLabel>
//         </IonSegmentButton>
//         <IonSegmentButton value="no">
//           <IonLabel>No</IonLabel>
//         </IonSegmentButton>
//       </IonSegment>
//     </IonCol>
//   </IonRow>
//   <IonRow>
//     <IonCol>
//       <p className="ion-padding questionP">Do you have Hypertension?</p>
//       <IonSegment
//         onIonChange={(e) => {
//           setHypertension(e.detail.value);
//           setValidHypertension(true);
//         }}
//       >
//         <IonSegmentButton value="yes">
//           <IonLabel>Yes</IonLabel>
//         </IonSegmentButton>
//         <IonSegmentButton value="no">
//           <IonLabel>No</IonLabel>
//         </IonSegmentButton>
//       </IonSegment>
//     </IonCol>
//   </IonRow>
//   <IonRow>
//     <IonCol>
//       <p className="ion-padding questionP">Do you have Diabetes?</p>
//       <IonSegment
//         onIonChange={(e) => {
//           setDiabetes(e.detail.value);
//           setValidDiabetes(true);
//         }}
//       >
//         <IonSegmentButton value="no">
//           <IonLabel>No</IonLabel>
//         </IonSegmentButton>
//         <IonSegmentButton value="type1">
//           <IonLabel>Type 1</IonLabel>
//         </IonSegmentButton>
//         <IonSegmentButton value="type2">
//           <IonLabel>Type 2</IonLabel>
//         </IonSegmentButton>
//       </IonSegment>
//     </IonCol>
//   </IonRow>
// </IonGrid>
//       </IonContent>
//       <IonButton
//         onClick={() => {
//           changeDirection("next");
//           addHealthData();
//         }}
//         className="ion-margin"
//         disabled={
//           !validSmoker ||
//           !validBloodPressureMeds ||
//           !validHypertension ||
//           !validDiabetes
//         }
//       >
//         CONTINUE
//       </IonButton>
//     </IonPage>
//   );
// };

// export default AddHealthInfo;
