/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
//import spinner
import React from "react";

import "./GuestScan.css";

const GuestScan: React.FC = () => {
  const [checked, setChecked] = React.useState(false);
  //FIXME learn how to not use this many use states
  const [age, setAge] = React.useState<number>();
  const [weight, setWeight] = React.useState<number>();
  const [heightIn, setHeightIn] = React.useState<number>();
  const [heightFt, setHeightFt] = React.useState<number>();
  const [smoker, setSmoke] = React.useState<string>();
  const [hypertension, setHypertension] = React.useState<string>();
  const [medication, setBloodPressure] = React.useState<string>();
  const [diabetic, setDiabetic] = React.useState<string>();
  const [gender, setGender] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  // this is what handles when the from is submited
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const body = {
      age: age,
      weight: weight,
      heightIn: heightIn,
      heightFt: heightFt,
      smoker: smoker,
      hypertension: hypertension,
      bloodPressureMeds: medication,
      diabetes: diabetic,
      gender: gender,
    };


    if (checked) {
      setLoading(true);
      axios
        .post(
          `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/DFX-GetMeasurementURLGuest`,
          body
        )
        .then((res) => {
          window.open(res.data, "_blank");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Please agree to the terms and conditions");
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="diagnostics" />
          </IonButtons>

          <IonTitle className="mainPageTitle">Guest Diagnostic Scan</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonCard className="form-card">
          <form className="form-card" onSubmit={handleSubmit}>
            <IonItem>
              <IonLabel position="stacked">Age</IonLabel>
              <IonInput
                required
                name="age"
                onIonChange={(e) => setAge(parseInt(e.detail.value!, 10))}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Weight (lb)</IonLabel>
              <IonInput
                required
                name="weight"
                onIonChange={(e) => setWeight(parseInt(e.detail.value!, 10))}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Heigth (ft in)</IonLabel>

              <IonInput
                placeholder="feet"
                required
                name="heightFt"
                onIonChange={(e) => setHeightFt(parseInt(e.detail.value!, 10))}
              ></IonInput>
              <IonInput
                placeholder="inches"
                required
                name="heightIn"
                onIonChange={(e) => setHeightIn(parseInt(e.detail.value!, 10))}
              ></IonInput>
            </IonItem>

            <IonLabel className="ion-padding">Gender at birth?*</IonLabel>
            <IonItem>
              <IonSegment onIonChange={(e) => setGender(e.detail.value!)}>
                <IonSegmentButton value="male">
                  <IonLabel>Male</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="female">
                  <IonLabel>Female</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonItem>

            <IonLabel className="ion-padding">Do you smoke?*</IonLabel>
            <IonItem>
              <IonSegment onIonChange={(e) => setSmoke(e.detail.value!)}>
                <IonSegmentButton value="yes">
                  <IonLabel>Yes</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="no">
                  <IonLabel>No</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonItem>

            <IonLabel className="ion-padding">
              Do you have hypertension (high blood pressure)?*
            </IonLabel>
            <IonItem>
              <IonSegment onIonChange={(e) => setHypertension(e.detail.value!)}>
                <IonSegmentButton value="yes">
                  <IonLabel>Yes</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="no">
                  <IonLabel>No</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonItem>

            <IonLabel className="ion-padding">
              Are you taking any blood<br></br> pressure medication?*
            </IonLabel>
            <IonItem>
              <IonSegment
                onIonChange={(e) => setBloodPressure(e.detail.value!)}
              >
                <IonSegmentButton value="yes">
                  <IonLabel>Yes</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="no">
                  <IonLabel>No</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonItem>

            <IonLabel className="ion-padding">
              Do you have Diabetes?*
            </IonLabel>
            <IonItem>
              <IonSegment onIonChange={(e) => setDiabetic(e.detail.value!)}>
                <IonSegmentButton value="type1">
                  <IonLabel>Type 1</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="type2">
                  <IonLabel>Type 2</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="no">
                  <IonLabel>No</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonItem>

            <IonItem>
              <IonLabel>
                I agree to the{" "}
                <a id="trigger-terms" className="terms">
                  terms of service
                </a>
              </IonLabel>
              <IonCheckbox
                slot="start"
                onIonChange={(e) => setChecked(e.detail.checked)}
              />
            </IonItem>

            <IonButton className="ion-margin" type="submit">
              {loading ? <IonSpinner /> : "Submit"}
            </IonButton>
          </form>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default GuestScan;
