import {
  InputChangeEventDetail,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { UserContext } from "../../lib/context";
import {
  deleteLogGlucoseDB,
  fetchLogGlucoseCollection,
  updateLogGlucoseDB,
} from "../../API/logupdateAPI";
import { Timestamp } from "firebase/firestore";
import { addCircleOutline, trashOutline } from "ionicons/icons";
import { useLocation } from "react-router-dom";
import { GlucoseLogTypes } from "../../Types/LogsTypes";
import { profile } from "console";

function LogGlucose2(props) {
  const { user, profile } = useContext(UserContext);
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [glucoseLevel, setGlucoseLevel] = useState<number | undefined>(
    undefined
  );
  const [currentLogGlucoseDocID, setCurrentLogGlucoseDocId] = useState<
    string | undefined
  >(undefined);
  const location = useLocation();
  const [pathName, setPathName] = useState("");
  const [userGlucoseDatabase, setUserGlucoseDatabase] = useState<
    GlucoseLogTypes[]
  >([]);

  const [when, setWhen] = useState<string>("");

  const fetchGlucoseDB = useCallback(async () => {
    const userGlucoseData = await fetchLogGlucoseCollection(
      user.uid,
      props.date
    );
    setUserGlucoseDatabase(userGlucoseData);
  }, [user.uid, props.date]);

  useEffect(() => {
    fetchGlucoseDB();
    setPathName(location.pathname);
  }, [props.date, location.pathname]);

  const handleUpdateLogGlucoseDoc = async () => {
    try {
      const timestamp = Timestamp.now();
      const docId = await updateLogGlucoseDB({
        email: user.email,
        timestamp: new Date(props.date),
        date: props.date,
        glucoseLevel: glucoseLevel,
        uid: user.uid,
        when: when,
      });
      fetchGlucoseDB();
      setGlucoseLevel(null);
      setCurrentLogGlucoseDocId(docId);
      modal.current?.dismiss(input.current?.value, "confirm");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleDeleteLogGlucoseDoc = async (docId: string) => {
    try {
      await deleteLogGlucoseDB(docId);
      // console.log("Document deleted successfully");
      fetchGlucoseDB();
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleInputChanged = (event: CustomEvent<InputChangeEventDetail>) => {
    const value = event.detail.value;
    setGlucoseLevel(value ? parseFloat(value) : undefined);
  };

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {}

  return (
    <>
      {profile.hasGlucose || profile.diabetes !== "no" ? (
        <>
          {" "}
          <IonCard>
            <IonCardHeader
              className="mood journal-head"
              id="open-modal-glucose2"
            >
              <IonRow>
                <IonCol size="8">
                  <IonCardTitle className="journal-head">
                    Log Glucose
                  </IonCardTitle>
                </IonCol>
                <IonCol size="4">
                  <IonIcon
                    className="icon_plus"
                    icon={addCircleOutline}
                  ></IonIcon>
                </IonCol>
              </IonRow>
            </IonCardHeader>
            <IonCardContent>
              {userGlucoseDatabase.map((userGlucose) =>
                userGlucose.date === props.date ? (
                  <IonItem key={userGlucose.id}>
                    <IonList>
                      <span>
                        {userGlucose.when}: {userGlucose.glucoseLevel}
                      </span>
                    </IonList>
                    <IonIcon
                      onClick={() => handleDeleteLogGlucoseDoc(userGlucose.id)}
                      slot="end"
                      icon={trashOutline}
                    ></IonIcon>
                  </IonItem>
                ) : null
              )}
            </IonCardContent>
          </IonCard>
          <IonModal
            ref={modal}
            trigger="open-modal-glucose2"
            onWillDismiss={(ev) => onWillDismiss(ev)}
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onClick={() => modal.current?.dismiss()}>
                    Cancel
                  </IonButton>
                </IonButtons>
                <IonTitle>Log Glucose</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              {/* <IonItem lines="none">
                <IonLabel className="ion-padding" position="stacked">
                  Enter your Glucose level
                </IonLabel>
                  <IonInput
                    ref={input}
                    type="number"
                    placeholder="123"
                    slot="start"
                    onIonChange={handleInputChanged}
                  />
                  <IonBadge slot="end">mg/dL</IonBadge>
              </IonItem> */}
              <IonItem className="ion-margin">
                <IonLabel position="floating">Glucose Level</IonLabel>
                <IonInput
                  type="number"
                  placeholder="87"
                  inputMode="numeric"
                  onIonChange={(e) => setGlucoseLevel(+e.detail.value)}
                  // onIonBlur={validateMealCalories}
                ></IonInput>
              </IonItem>
              <IonItem className="ion-margin">
                <IonLabel>When?</IonLabel>
                <IonSelect
                  onIonChange={(e) => setWhen(e.detail.value)}
                  // onIonDismiss={validateMealType}
                >
                  <IonSelectOption value="Fasting Glucose">
                    Fasting Glucose
                  </IonSelectOption>
                  <IonSelectOption value="Before Bed">
                    Before Bed
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonButton
                className="ion-padding"
                expand="block"
                onClick={() => handleUpdateLogGlucoseDoc()}
                disabled={glucoseLevel === undefined || when === ""}
              >
                Log
              </IonButton>
            </IonContent>
          </IonModal>
        </>
      ) : null}
    </>
  );
}

export default LogGlucose2;
