// loading spinner
//import spinner css
import { IonLabel, IonPage, IonContent } from "@ionic/react";
import React from "react";
import "./Loader.css";
export default function Loader() {

  

  const MessagesLoader = (props) => {

    const messages = [
      "Loading...",
      "Fetching user data...",
      "Syncing with the cloud...",
      "Checking network status...",
      "It appears you are having internet issues...",
      "It appears you are having internet issues...",
    ];

    // Default to the first message passed
    const [messageIndex, setMessageIndex] = React.useState(0);

    React.useEffect(() => {
      // Move on to the next message every `n` milliseconds
      let timeout;
      if (messageIndex < messages.length - 1) {
        timeout = setTimeout(() => setMessageIndex(messageIndex + 1), 4500);
      } else {
        setMessageIndex(0);
      }

      return () => {
        clearTimeout(timeout);
      };
    }, [messages, messageIndex]);

    return (
      <div>
        <IonLabel className="ion-margin" color="primary">
          <p className="primaryColor">{messages[messageIndex]}</p>
        </IonLabel>
      </div>
    );
  };

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="middle">
          <div className="center-container">
            <>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/htm-app-9b541.appspot.com/o/AlliedWellnessLogo.png?alt=media&token=41d93b26-9a0e-4590-a3f4-90ca72616230"
                  className="mylogo"
                  alt="logo"
                />
              <MessagesLoader />
            </>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
