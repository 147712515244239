import {
  IonItem,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToolbar,
  IonContent,
  IonList,
  IonTitle,
} from "@ionic/react";
import {
  addOutline,
  body,
  chevronForward,
  closeOutline,
  thumbsDown,
  thumbsUp,
} from "ionicons/icons";
import "./style.css";
import { useContext, useEffect, useState } from "react";
import { InterestList } from "../../../Interfaces/Interest";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
} from "firebase/firestore";
import { UserContext } from "../../../../../lib/context";
import QuestionCard from "../../QuestionCard";

type Props = {};

const DownvotedPost: React.FC<Props> = () => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const { koroPublicAvatar } = useContext(UserContext);

  const [questionsData, setQuestionsData] = useState([]);

  const fetchUpvotes = async () => {
    // fetch upvotes from firebase

    // console.log("fetching upvotes");

    var questions = [];

    var q = query(
      collection(getFirestore(), "koroQuestions"),
      where("downvotes", "array-contains", koroPublicAvatar.uid)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      questions.push({ id: doc.id, ...doc.data() });
    });

    // sort questions by timestamp.seconds in ascending order
    questions.sort((a, b) => {
      return b.dateCreated.seconds - a.dateCreated.seconds;
    });

    // console.log(questions);

    setQuestionsData(questions);
  };

  useEffect(() => {
    if (detailsOpen == false || questionsData.length > 0) {
      return;
    }

    fetchUpvotes();
  }, [detailsOpen]);

  const refreshHandler = () => {
    // setRefresh(!refresh);
  };

  return (
    <>
      <IonItem lines="none" detail={false} onClick={() => setDetailsOpen(true)}>
        <IonIcon icon={chevronForward} slot="end"></IonIcon>
        <IonIcon icon={thumbsDown} slot="start"></IonIcon>
        <p className="settingsHeaderCardInfo">Downvoted Post</p>
      </IonItem>
      <IonModal isOpen={detailsOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Downvoted Posts</IonTitle>

            <IonButtons>
              <IonButton onClick={() => setDetailsOpen(false)} slot="start">
                <IonIcon icon={closeOutline} size="large" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="backgroundColor ion-padding-bottom">
          {questionsData.length === 0 && (
            <p className="ion-text-center ion-padding">
              You have not downvoted any questions yet...
            </p>
          )}
          <IonList>
            {questionsData.map((question) => (
              <QuestionCard
                question={question}
                refresh={false}
                key={question.id}
                refreshHandler={refreshHandler}
              />
            ))}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default DownvotedPost;
