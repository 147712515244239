import {
  IonModal,
  IonContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonHeader,
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import React from "react";
import { useRef, useState } from "react";
import { addDoc, collection, doc, getFirestore, updateDoc } from "firebase/firestore";
import { personAdd } from "ionicons/icons";

import SuperHappyFilled from "../../assets/icons/super-happy-filled.svg";
import HappyFilled from "../../assets/icons/happy-filled.svg";
import NeutralFilled from "../../assets/icons/neutral-filled.svg";
import SadFilled from "../../assets/icons/sad-filled.svg";
import SupperSadFilled from "../../assets/icons/super-sad-filled.svg";

import "./LogMood.css";

export default function LogMood(props) {
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [mood, setMood] = useState<string>();
  const [moodComment, setMoodComment] = useState<string>("");

  function confirm() {
    modal.current?.dismiss(input.current?.value, "confirm");
  }

  async function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {

    const dateArray = props.date.split("-");
    const timestamp = new Date( parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]));

    if (ev.detail.role === "confirm") {
      await addDoc(collection(getFirestore(), "moods"), {
        mood: mood,
        comment: moodComment,
        date: props.date,
        timestamp: timestamp,
        uid: props.user.uid,
        email: props.user.email,
      }).then(() => {
        updateDoc(doc(getFirestore(), "tracking", props.user.uid), {
          lastMoodLog: new Date(),
          email: props.user.email,
          uid: props.user.uid
        });
      });

      setMoodComment("");
      setMood("");
     
    }
  }



  const validateMoodComment = () => {
    var moodCommentHelper = document.getElementById("moodCommentHelp");
    if (moodComment === undefined) {
      moodCommentHelper.innerHTML = "Please enter a mood comment";
      return false;
    } else {
      moodCommentHelper.innerHTML = "";
      return true;
    }
  };

  

  const moodSelected = (mood) => {
    setMood(mood);

    const superHappy = document.getElementById("super-happy");
    const happy = document.getElementById("happy");
    const neutral = document.getElementById("neutral");
    const sad = document.getElementById("sad");
    const superSad = document.getElementById("super-sad");

    superHappy.classList.remove("selected");
    happy.classList.remove("selected");
    neutral.classList.remove("selected");
    sad.classList.remove("selected");
    superSad.classList.remove("selected");

    if (mood === "super-happy") {
      superHappy.classList.add("selected");
    }

    if (mood === "happy") {
      happy.classList.add("selected");
    }

    if (mood === "neutral") {
      neutral.classList.add("selected");
    }


    if (mood === "sad") {
      sad.classList.add("selected");
    }
    if (mood === "super-sad") {
      superSad.classList.add("selected");
    }
  };

  return (
    <>
      <IonModal
        ref={modal}
        trigger="open-log-mood2"
        onWillDismiss={(ev) => onWillDismiss(ev)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle className="mainPageTitle">Log Mood</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding ion-text-center">
          <IonGrid>
            <IonRow>
              <IonCol>
                <div
                  onClick={(e) => moodSelected("super-happy")}
                  id="super-happy"
                >
                  <IonIcon className="mood-icon" icon={SuperHappyFilled} />
                </div>
              </IonCol>
              <IonCol>
                <div onClick={(e) => moodSelected("happy")} id="happy">
                  <IonIcon className="mood-icon" icon={HappyFilled} />
                </div>
              </IonCol>
              <IonCol>
                <div onClick={(e) => moodSelected("neutral")} id="neutral">
                  {" "}
                  <IonIcon className="mood-icon" icon={NeutralFilled} />{" "}
                </div>
              </IonCol>
              <IonCol>
                <div onClick={(e) => moodSelected("sad")} id="sad">
                  {" "}
                  <IonIcon className="mood-icon" icon={SadFilled} />{" "}
                </div>
              </IonCol>
              <IonCol>
                <div onClick={(e) => moodSelected("super-sad")} id="super-sad">
                  <IonIcon className="mood-icon" icon={SupperSadFilled} />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonItem>
            <IonLabel position="floating">Comment</IonLabel>
            <IonInput
              type="text"
              autoCapitalize="on"
              autoCorrect="on"
              placeholder="Ex: I felt anxiouse when I got to work"
              onIonChange={(e) => setMoodComment(e.detail.value)}
              onIonBlur={validateMoodComment}
            ></IonInput>
            <p className="formHelp" id="moodCommentHelp"></p>
          </IonItem>
          <IonButton
            className="ion-padding"
            expand="block"
            disabled={(mood === undefined || mood === "")}
            onClick={() => confirm()}
          >
            Log
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
}
