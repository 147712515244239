import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
//import spinner
import { useState } from "react";
import React from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { EmailAuthProvider } from "firebase/auth";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";

const EditHealthInfo: React.FC = () => {
  //email uststate
  const [email, setEmail] = useState();
  const [confirmEmail, setConfirmEmail] = useState();
  const [password, setPassword] = useState();
  const [completed, setCompleted] = useState(false);
  const [presentAlert] = useIonAlert();


  //email validation
  const validateEmail = () => {
    var emailHelper = document.getElementById("emailHelp");
    var regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === undefined || email === "") {
      emailHelper.innerHTML = "Email is required";
      return false;
    } else if (!regex.test(email)) {
      emailHelper.innerHTML = "Email is invalid";
      return false;
    } else {
      emailHelper.innerHTML = "";
      return true;
    }
  };

  const validateConfirmEmail = () => {
    var confirmEmailHelper = document.getElementById("emailConfirmHelp");

    if (confirmEmail === undefined || confirmEmail === "") {
      confirmEmailHelper.innerHTML = "Confirm email is required";
      return false;
    } else if (confirmEmail !== email) {
      confirmEmailHelper.innerHTML = "Emails do not match";
      return false;
    } else {
      confirmEmailHelper.innerHTML = "";
      return true;
    }
  };

  const updateEmailEverywhere = async (e) => {
    var passwordHelper = document.getElementById("passwordHelp");

    e.preventDefault();

    const user = firebase.auth().currentUser;

    const credential = EmailAuthProvider.credential(user.email, password);

    if (validateEmail() && validateConfirmEmail()) {
      user
        .reauthenticateWithCredential(credential)
        .then(async () => {
          //console.log("Reauthenticated");
          var ref = doc(getFirestore(), "users", user.uid);
          await updateDoc(ref, {
            email: email,
          });

          user
            .updateEmail(email)
            .then(() => {
              // Update successful
              presentAlert({
                header: 'Success',
                message: 'Your email has been updated',
                buttons: ['OK'],
              })
              //console.log("Email updated");
              // ...
            })
            .catch((error) => {
              // An error occurred
              // console.log("Error updating email");
              console.log(error);
              // ...
            });
        })
        .catch((error) => {
          // An error happened.
          console.log(error);
          // An error occurred
          // ...
        });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="settings" />
          </IonButtons>

          <IonTitle className="mainPageTitle">Change Email</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
              <form onSubmit={updateEmailEverywhere}>
                <IonItem>
                  <IonLabel position="stacked">Password</IonLabel>
                  <IonInput
                    type="password"
                    onIonChange={(e: any) => {
                      setPassword(e.detail.value);
                    }}
                  ></IonInput>
                  <p className="formHelp" id="passwordHelp"></p>
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">New Email</IonLabel>
                  <IonInput
                    type="email"
                    onIonChange={(e: any) => {
                      setEmail(e.detail.value);
                    }}
                    onIonBlur={validateEmail}
                  ></IonInput>
                  <p className="formHelp" id="emailHelp"></p>
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Confirm New Email</IonLabel>
                  <IonInput
                    type="email"
                    onIonChange={(e: any) => {
                      setConfirmEmail(e.detail.value);
                    }}
                    onIonBlur={validateConfirmEmail}
                  ></IonInput>
                  <p className="formHelp" id="emailConfirmHelp"></p>
                </IonItem>

                <div id="login-help"></div>

                <IonButton type="submit" expand="block" className="ion-padding">
                  Update
                </IonButton>
              </form>
      </IonContent>
    </IonPage>
  );
};

export default EditHealthInfo;
