import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { checkbox, squareOutline } from "ionicons/icons";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../lib/context";

import StartTimePretty from "./StartTimePretty";

import "./Session.css";
import { Router } from "workbox-routing";
import CoachInfoLink from "./CoachInfoLink";

const Session: React.FC = (props) => {
  const [appointment, setAppointment] = useState({});
  const [documents, setDocuments] = useState([]);
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const [presentAlert] = useIonAlert();

  const { user } = useContext(UserContext);

  //get session ID from url and find in firebase
  const fetchSessions = async () => {
    const id = window.location.pathname.split("/")[2];

    const docRef = doc(getFirestore(), "sessions", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setAppointment({ id: docSnap.id, ...docSnap.data() });
    } else {
      // doc.data() will be undefined in this case
    }
  };

  const fetchDocuments = async () => {
    //get sessiosn from firebase
    const id = window.location.pathname.split("/")[2];
    const q = query(
      collection(getFirestore(), "session_documents"),
      where("fromSession", "==", id)
    );

    var documentsList = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      documentsList.push({ id: doc.id, ...doc.data() });
    });

    setDocuments(documentsList);
  };

  const RenderDocuments = () => {
    let documentList = [];
    documents.forEach((document, index) => {
      documentList.push(
        <p key={document.id}>
          <a href={document.url}>{document.title}</a>
        </p>
      );
    });

    if (documentList.length == 0) {
      return <div>No Documents</div>;
    }

    return <>{documentList}</>;
  };

  //this function compnent will add a document to firebase storage then will make a document in firestore as a 'reference'
  const AddDocument = () => {
    const [documentFile, setDocumentFile] = useState<File>();

    //THis function handels the file uploading
    const addFile = (e: any) => {
      const id = window.location.pathname.split("/")[2];

      //checking to make sure file has been set
      //Adds documenet to firebase storage and wait for download link to be returned. Once Download link has been returned then adds the reference in firebase
      if (documentFile) {
        const storage = getStorage();
        //creating ref
        const fileRef = ref(storage, "session_documents/" + documentFile.name);
        uploadBytes(fileRef, documentFile).then(() => {
          getDownloadURL(fileRef).then(async (url) => {
            const post = {
              fromSession: id,
              title: documentFile.name,
              url: url,
              posterID: user.uid,
            };

            await addDoc(collection(getFirestore(), "session_documents"), post);
            fetchDocuments();
          });
        });
      } else {
        // console.log("No file selected");
      }
    };

    return (
      <>
        <IonItem>
          <input
            type="file"
            onChange={(e) => setDocumentFile(e.target.files[0])}
          ></input>
        </IonItem>
        <IonButton expand="block" onClick={addFile}>
          Add Document
        </IonButton>
      </>
    );
  };

  useEffect(() => {
    //
    fetchSessions();
    fetchDocuments();
  }, []);

  const RenderCoachInfo = ({ coachUid }) => {
    const [coachData, setCoachData] = useState({});
    const coachRef = doc(getFirestore(), "users", coachUid);
    getDoc(coachRef).then((doc) => {
      if (doc.exists()) {
        setCoachData(doc.data());
      } else {
        // doc.data() will be undefined in this case
      }
    });

    return (
      <>
        {" "}
        <IonCardHeader className="ion-text-center">
          {appointment["status"] == "cancelled" || cancelled ? (
            <b>CANCELLED</b>
          ) : (
            ""
          )}

          <CoachInfoLink uid={coachUid} name={coachData["firstName"] + " " + coachData["lastName"]} location={appointment["eventTypeName"]} photo={coachData["photoURL"]}/>

       
          <IonLabel><p className="ion-padding-top">{coachData["email"]}</p></IonLabel>
        </IonCardHeader>
        <IonCardContent>
          {/* {appointment["coachEmail"]} */}
          <p className="session-time-pretty">
            <StartTimePretty startTime={appointment["startTime"]} />
          </p>
        </IonCardContent>
      </>
    );
  };

  const RenderJoinCallButton = ({ cometChatUid, startTime, location }) => {
    const startTimeArray = startTime.split("-");
    // console.log(startTimeArray);
    const year = startTimeArray[0];
    const month = startTimeArray[1];
    const day = startTimeArray[2].split("T")[0];

    const isToday =
      new Date().getFullYear() == year &&
      new Date().getMonth() + 1 == month &&
      new Date().getDate() == day;
    // console.log(isToday);

    const isBeforeToday =
      new Date().getFullYear() > year ||
      (new Date().getFullYear() == year &&
        new Date().getMonth() + 1 > month &&
        new Date().getDate() > day);

    const isPastToday =
      new Date().getFullYear() < year ||
      (new Date().getFullYear() == year &&
        new Date().getMonth() + 1 < month &&
        new Date().getDate() < day);

    //check to see if location is a phone number
    if (location.length < 17)
      return <p>The coach will call you when it is time for your session</p>;

    //if location contains the word calendly
    if (location.includes("calendly"))
      return (
        <IonButton
          disabled={
            !isToday || appointment["status"] == "cancelled" || cancelled
          }
          href={location}
          className="ion-margin-bottom join-session-button"
          expand="block"
          target="_blank"
        >
          Join
        </IonButton>
      );

    return (
      <>
        <IonButton
          disabled={
            !isToday || appointment["status"] == "cancelled" || cancelled
          }
          routerLink={"/meeting/" + window.location.pathname.split("/")[2]}
          className="ion-margin-bottom join-session-button"
          expand="block"
        >
          Join!
        </IonButton>
      </>
    );
  };

  const cancelSession = async () => {
    const id = window.location.pathname.split("/")[2];
    const docRef = doc(getFirestore(), "sessions", id);
    await updateDoc(docRef, {
      status: "cancelled",
    });
    fetchSessions();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="mainHeaderBackground">
          <IonButtons slot="start">
            <IonBackButton
              className="mainPageTitleTEst"
              defaultHref="/tabs/home"
            />
          </IonButtons>
          <IonTitle className="mainPageTitleTEst">Session Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-text-center ion-padding">
        {appointment["coachUid"] !== undefined ? (
         <>
            <RenderCoachInfo coachUid={appointment["coachUid"]} />

            <RenderJoinCallButton
              cometChatUid={appointment["coachCometChatUid"]}
              startTime={appointment["startTime"]}
              location={appointment["location"]}
            />

            <div className="ion-padding-bottom">
              <a
                className="session-cancel"
                onClick={() => setShowCancelAlert(true)}
              >
                Cancel
              </a>
            </div>
            <IonAlert
              isOpen={showCancelAlert}
              onDidDismiss={() => setShowCancelAlert(false)}
              message="Are you sure you want to cancel this appointment? This action cannot be undone."
              buttons={[
                {
                  text: "No",
                  role: "cancel",
                  handler: () => {},
                },
                {
                  text: "Yes",
                  role: "confirm",
                  handler: () => {
                    cancelSession();
                    setCancelled(true);
                  },
                },
              ]}
            />
            </>
        ) : (
          <IonCard className="session-card">
            <IonCardHeader>
              <IonCardTitle>Appointment Pending</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p> Your appointment is pending. Please come back later.</p>
              <IonButton routerLink="/tabs/home">Back</IonButton>
            </IonCardContent>
          </IonCard>
        )}

        {appointment["coachNotes"] && (
          <>
            {" "}
            <IonCard className="session-card ion-padding ">
              {/* <IonCardHeader>
            <IonCardTitle>Notes</IonCardTitle>
          </IonCardHeader> */}
              <IonCardContent>
                {/* <p>{appointment["coachNotes"]}</p> */}
                <IonLabel>
                  <h1 className="ion-padding">Notes</h1>
                </IonLabel>
                <IonLabel>
                  <p>{appointment["coachNotes"]}</p>
                </IonLabel>
              </IonCardContent>
            </IonCard>
          </>
        )}

        {/* <IonCard>
          <IonCardHeader>
            <IonCardTitle>Documents</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <RenderDocuments />
            <AddDocument />
          </IonCardContent>
        </IonCard> */}
      </IonContent>
    </IonPage>
  );
};

export default Session;
